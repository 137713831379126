<script setup>
import { ref, watchEffect, computed, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import KanbanAPI from '../../../../api/kanban';
import FunnelSelector from './FunnelSelector.vue';
import agents from '../../../../api/agents';
import conversationAPI from '../../../../api/inbox/conversation';

const { t } = useI18n();
const store = useStore();
const emit = defineEmits(['saved', 'close']);

const props = defineProps({
  funnelId: {
    type: [String, Number],
    required: true,
  },
  stage: {
    type: String,
    required: true,
  },
  position: {
    type: Number,
    default: 0,
  },
  initialData: {
    type: Object,
    default: null,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  currentConversation: {
    type: Object,
    default: null,
  },
});

const loading = ref(false);
const agentsList = ref([]);
const loadingAgents = ref(false);
const selectedFunnel = computed(
  () => store.getters['funnel/getSelectedFunnel']
);

// Computed para obter as etapas do funil selecionado
const availableStages = computed(() => {
  if (!selectedFunnel.value?.stages) return [];

  return Object.entries(selectedFunnel.value.stages)
    .map(([id, stage]) => ({
      id,
      name: stage.name,
      position: stage.position,
    }))
    .sort((a, b) => a.position - b.position);
});

// Refs para controle da conversa
const showConversationInput = ref(
  !!props.initialData?.item_details?.conversation_id
);
const conversations = ref([]);
const loadingConversations = ref(false);

// Ref para controlar a visibilidade do input de valor
const showValueInput = ref(!!props.initialData?.item_details?.value);

// Atualizar o form.value para incluir conversation_id
const form = ref({
  title:
    props.initialData?.title || props.initialData?.item_details?.title || '',
  description: props.initialData?.description || '',
  funnel_id: props.funnelId,
  funnel_stage: props.stage,
  position: props.position,
  item_details: {
    title:
      props.initialData?.item_details?.title || props.initialData?.title || '',
    description: props.initialData?.item_details?.description || '',
    value: props.initialData?.item_details?.value || null,
    priority:
      props.initialData?.item_details?.priority ||
      props.initialData?.priority ||
      'none',
    agent_id:
      props.initialData?.item_details?.agent_id ||
      props.initialData?.item_details?._agent?.id ||
      null,
    conversation_id: props.initialData?.item_details?.conversation_id || null,
  },
  custom_attributes: props.initialData?.custom_attributes || {},
});

// Função para buscar conversas
const fetchConversations = async () => {
  try {
    loadingConversations.value = true;
    // Se estiver editando, busca todas as conversas
    if (props.isEditing) {
      const response = await conversationAPI.get({});
      conversations.value = response.data.data.payload;
    } else if (props.currentConversation) {
      // Usa a conversa atual se fornecida
      conversations.value = [props.currentConversation];
    } else {
      // Caso contrário, busca todas as conversas
      const response = await conversationAPI.get({});
      conversations.value = response.data.data.payload;
    }
  } catch (error) {
    console.error('Erro ao carregar conversas:', error);
  } finally {
    loadingConversations.value = false;
  }
};

// Watch para limpar o valor quando o checkbox é desmarcado
watch(showValueInput, newValue => {
  if (!newValue) {
    form.value.item_details.value = null;
  }
});

watch(showConversationInput, newValue => {
  if (!newValue) {
    form.value.item_details.conversation_id = null;
  } else {
    fetchConversations();
  }
});

// Atualiza o form quando o funil ou etapa mudar
watchEffect(() => {
  form.value = {
    ...form.value,
    funnel_id: selectedFunnel.value?.id || props.funnelId,
    funnel_stage: form.value.funnel_stage || props.stage,
    position: props.position,
    title:
      form.value.title ||
      props.initialData?.title ||
      props.initialData?.item_details?.title ||
      '',
    item_details: {
      ...form.value.item_details,
      title:
        form.value.item_details.title ||
        props.initialData?.title ||
        props.initialData?.item_details?.title ||
        '',
      priority:
        form.value.item_details.priority ||
        props.initialData?.item_details?.priority ||
        props.initialData?.priority ||
        'none',
    },
  };
});

const validateForm = () => {
  const errors = {};

  if (!form.value.title?.trim()) {
    errors.title = t('KANBAN.ERRORS.TITLE_REQUIRED');
  }

  if (!form.value.funnel_id) {
    errors.funnel_id = t('KANBAN.ERRORS.FUNNEL_REQUIRED');
  }

  if (!form.value.funnel_stage) {
    errors.funnel_stage = t('KANBAN.ERRORS.STAGE_REQUIRED');
  }

  return Object.keys(errors).length === 0 ? null : errors;
};

const priorityOptions = [
  { id: 'none', name: t('PRIORITY_LABELS.NONE') },
  { id: 'low', name: t('PRIORITY_LABELS.LOW') },
  { id: 'medium', name: t('PRIORITY_LABELS.MEDIUM') },
  { id: 'high', name: t('PRIORITY_LABELS.HIGH') },
  { id: 'urgent', name: t('PRIORITY_LABELS.URGENT') },
];

// Função para carregar os agentes
const fetchAgents = async () => {
  try {
    loadingAgents.value = true;
    const { data } = await agents.get();
    // Se tiver um agente pré-selecionado e ele não estiver na lista, adiciona-o
    if (props.initialData?.item_details?._agent) {
      const agent = props.initialData.item_details._agent;
      if (!data.find(a => a.id === agent.id)) {
        data.push(agent);
      }
    }
    agentsList.value = data;
  } catch (error) {
    console.error('Erro ao carregar agentes:', error);
  } finally {
    loadingAgents.value = false;
  }
};

// Carregar agentes quando o componente for montado
onMounted(() => {
  fetchAgents();

  // Se tiver conversa atual ou estiver editando, buscar conversas
  if (
    props.currentConversation ||
    (props.isEditing && props.initialData?.item_details?.conversation_id)
  ) {
    showConversationInput.value = true;
    fetchConversations();
  }
});

const handleSubmit = async () => {
  try {
    const errors = validateForm();
    if (errors) {
      console.error('Erros de validação:', errors);
      return;
    }

    loading.value = true;

    // Garantir que todos os campos obrigatórios estejam presentes e válidos
    const payload = {
      funnel_id: Number(form.value.funnel_id) || props.funnelId,
      funnel_stage: form.value.funnel_stage || props.stage,
      position: form.value.position || 0,
      item_details: {
        title: form.value.title?.trim() || '',
        description: form.value.description?.trim() || '',
        priority: form.value.item_details?.priority || 'none',
        agent_id: form.value.item_details?.agent_id || null,
        conversation_id: form.value.item_details?.conversation_id || null,
        value: form.value.item_details?.value
          ? Number(form.value.item_details.value)
          : null,
        // Garantir que outros campos necessários estejam presentes
        status: form.value.item_details?.status || 'active',
        type: form.value.item_details?.type || 'default',
      },
      custom_attributes: form.value.custom_attributes || {},
    };

    // Validação mais rigorosa
    if (
      !payload.funnel_id ||
      !payload.funnel_stage ||
      !payload.item_details.title ||
      typeof payload.funnel_stage !== 'string' // Garantir que funnel_stage seja string
    ) {
      throw new Error('Campos obrigatórios faltando ou inválidos');
    }

    const { data } = props.isEditing
      ? await KanbanAPI.updateItem(props.initialData.id, payload)
      : await KanbanAPI.createItem(payload);

    // Garantir que a resposta tenha todos os campos necessários
    if (!data || !data.funnel_stage || !data.item_details) {
      throw new Error('Resposta inválida do servidor');
    }

    await store.dispatch('kanban/itemUpdated');
    emit('saved', data);
  } catch (error) {
    console.error(
      'Erro ao salvar item do kanban:',
      error.response?.data || error
    );
    // Opcionalmente, você pode querer mostrar um toast/notificação de erro aqui
  } finally {
    loading.value = false;
  }
};
</script>

<template>
  <form class="space-y-4" @submit.prevent="handleSubmit">
    <!-- Seletor de Funil -->
    <div class="space-y-2">
      <label
        class="block text-sm font-medium text-slate-700 dark:text-slate-300"
      >
        {{ t('KANBAN.FORM.FUNNEL.LABEL') }}
      </label>
      <FunnelSelector class="w-full" />
    </div>

    <!-- Seletor de Etapa -->
    <div class="space-y-2">
      <label
        class="block text-sm font-medium text-slate-700 dark:text-slate-300"
      >
        {{ t('KANBAN.FORM.STAGE.LABEL') }}
      </label>
      <select
        v-model="form.funnel_stage"
        class="w-full px-3 py-2 border border-slate-300 rounded-lg focus:ring-1 focus:ring-woot-500 focus:border-woot-500 dark:bg-slate-700 dark:border-slate-600"
        :disabled="!selectedFunnel?.id"
      >
        <option value="" disabled>
          {{ t('KANBAN.FORM.STAGE.PLACEHOLDER') }}
        </option>
        <option
          v-for="stage in availableStages"
          :key="stage.id"
          :value="stage.id"
        >
          {{ stage.name }}
        </option>
      </select>
    </div>

    <!-- Título -->
    <div class="space-y-2">
      <label
        class="block text-sm font-medium text-slate-700 dark:text-slate-300"
      >
        {{ t('KANBAN.FORM.TITLE.LABEL') }}
      </label>
      <input
        v-model="form.title"
        type="text"
        class="w-full px-3 py-2 border border-slate-300 rounded-lg focus:ring-1 focus:ring-woot-500 focus:border-woot-500 dark:bg-slate-700 dark:border-slate-600"
        :placeholder="t('KANBAN.FORM.TITLE.PLACEHOLDER')"
        required
      />
    </div>

    <!-- Descrição -->
    <div class="space-y-2">
      <label
        class="block text-sm font-medium text-slate-700 dark:text-slate-300"
      >
        {{ t('KANBAN.FORM.DESCRIPTION.LABEL') }}
      </label>
      <textarea
        v-model="form.description"
        rows="3"
        class="w-full px-3 py-2 border border-slate-300 rounded-lg focus:ring-1 focus:ring-woot-500 focus:border-woot-500 dark:bg-slate-700 dark:border-slate-600"
        :placeholder="t('KANBAN.FORM.DESCRIPTION.PLACEHOLDER')"
      />
    </div>

    <!-- Valor (com checkbox) -->
    <div class="space-y-2">
      <div class="flex items-center">
        <input
          id="has-value"
          v-model="showValueInput"
          type="checkbox"
          class="w-4 h-4 text-woot-500 border-slate-300 rounded focus:ring-woot-500 dark:border-slate-600 dark:bg-slate-700"
        />
        <label
          for="has-value"
          class="ml-2 text-sm font-medium text-slate-700 dark:text-slate-300"
        >
          {{ t('KANBAN.FORM.VALUE.HAS_VALUE') }}
        </label>
      </div>

      <div v-if="showValueInput" class="mt-2">
        <input
          v-model="form.item_details.value"
          type="number"
          min="0"
          step="0.01"
          class="w-full px-3 py-2 border border-slate-300 rounded-lg focus:ring-1 focus:ring-woot-500 focus:border-woot-500 dark:bg-slate-700 dark:border-slate-600"
          :placeholder="t('KANBAN.FORM.VALUE.PLACEHOLDER')"
        />
      </div>
    </div>

    <!-- Prioridade -->
    <div class="space-y-2">
      <label
        class="block text-sm font-medium text-slate-700 dark:text-slate-300"
      >
        {{ t('KANBAN.FORM.PRIORITY.LABEL') }}
      </label>
      <select
        v-model="form.item_details.priority"
        class="w-full px-3 py-2 border border-slate-300 rounded-lg focus:ring-1 focus:ring-woot-500 focus:border-woot-500 dark:bg-slate-700 dark:border-slate-600"
      >
        <option
          v-for="option in priorityOptions"
          :key="option.id"
          :value="option.id"
        >
          {{ option.name }}
        </option>
      </select>
    </div>

    <!-- Seletor de Agente -->
    <div class="space-y-2">
      <label
        class="block text-sm font-medium text-slate-700 dark:text-slate-300"
      >
        {{ t('KANBAN.FORM.AGENT.LABEL') }}
      </label>
      <div class="relative">
        <select
          v-model="form.item_details.agent_id"
          class="w-full px-3 py-2 border border-slate-300 rounded-lg focus:ring-1 focus:ring-woot-500 focus:border-woot-500 dark:bg-slate-700 dark:border-slate-600"
          :disabled="loadingAgents"
        >
          <option value="">
            {{ t('KANBAN.FORM.AGENT.PLACEHOLDER') }}
          </option>
          <option v-for="agent in agentsList" :key="agent.id" :value="agent.id">
            {{ agent.name }}
          </option>
        </select>
        <div
          v-if="loadingAgents"
          class="absolute right-2 top-1/2 transform -translate-y-1/2"
        >
          <span class="loading-spinner" />
        </div>
      </div>
    </div>

    <!-- Conversa Relacionada -->
    <div class="space-y-2">
      <div class="flex items-center">
        <input
          id="has-conversation"
          v-model="showConversationInput"
          type="checkbox"
          class="w-4 h-4 text-woot-500 border-slate-300 rounded focus:ring-woot-500 dark:border-slate-600 dark:bg-slate-700"
        />
        <label
          for="has-conversation"
          class="ml-2 text-sm font-medium text-slate-700 dark:text-slate-300"
        >
          {{ t('KANBAN.FORM.CONVERSATION.HAS_CONVERSATION') }}
        </label>
      </div>

      <div v-if="showConversationInput" class="mt-2">
        <select
          v-model="form.item_details.conversation_id"
          class="w-full px-3 py-2 border border-slate-300 rounded-lg focus:ring-1 focus:ring-woot-500 focus:border-woot-500 dark:bg-slate-700 dark:border-slate-600"
          :disabled="loadingConversations"
        >
          <option value="">
            {{ t('KANBAN.FORM.CONVERSATION.PLACEHOLDER') }}
          </option>
          <option
            v-for="conversation in conversations"
            :key="conversation.id"
            :value="conversation.id"
          >
            #{{ conversation.id }} -
            {{
              conversation.meta.sender.name || conversation.meta.sender.email
            }}
            -
            {{ conversation.messages[0]?.content || 'Sem mensagens' }}
          </option>
        </select>
        <div
          v-if="loadingConversations"
          class="mt-1 text-xs text-slate-500 dark:text-slate-400"
        >
          {{ t('KANBAN.FORM.CONVERSATION.LOADING') }}
        </div>
      </div>
    </div>

    <!-- Botões -->
    <div class="flex justify-end space-x-2">
      <woot-button
        variant="clear"
        color-scheme="secondary"
        @click="emit('close')"
      >
        {{ t('CANCEL') }}
      </woot-button>
      <woot-button
        variant="solid"
        color-scheme="primary"
        :loading="loading"
        type="submit"
      >
        {{ t('SAVE') }}
      </woot-button>
    </div>
  </form>
</template>
