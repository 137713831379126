<script setup>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import FunnelsHeader from './FunnelsHeader.vue';
import Modal from '../../../../components/Modal.vue';
import FunnelAPI from '../../../../api/funnel';
import FunnelForm from './FunnelForm.vue';

const { t } = useI18n();
const store = useStore();
const loading = ref(false);
const funnels = ref([]);

// Estado para os modais
const showDeleteModal = ref(false);
const showEditModal = ref(false);
const funnelToDelete = ref(null);
const funnelToEdit = ref(null);

const fetchFunnels = async () => {
  try {
    loading.value = true;
    const response = await store.dispatch('funnel/fetch');
    if (Array.isArray(response)) {
      funnels.value = response.map(funnel => {
        // Primeiro converte as stages em array e ordena
        const orderedStages = Object.entries(funnel.stages || {})
          .map(([key, stage]) => ({
            ...stage,
            id: String(stage.id || key),
          }))
          .sort((a, b) => a.position - b.position);

        // Converte de volta para objeto mantendo a ordem
        const orderedStagesObj = orderedStages.reduce(
          (acc, stage) => ({
            ...acc,
            [stage.id]: stage,
          }),
          {}
        );

        return {
          ...funnel,
          id: String(funnel.id),
          stages: orderedStagesObj,
        };
      });
    } else {
      funnels.value = [];
      console.error('Resposta da API inválida:', response);
    }
  } catch (error) {
    console.error('Erro ao carregar funis:', error);
    funnels.value = [];
  } finally {
    loading.value = false;
  }
};

const confirmDelete = funnel => {
  funnelToDelete.value = funnel;
  showDeleteModal.value = true;
};

const handleDelete = async () => {
  try {
    loading.value = true;
    await FunnelAPI.delete(funnelToDelete.value.id);
    await fetchFunnels();
    showDeleteModal.value = false;
    funnelToDelete.value = null;
  } catch (error) {
    console.error('Erro ao excluir funil:', error);
  } finally {
    loading.value = false;
  }
};

const startEdit = funnel => {
  funnelToEdit.value = {
    ...funnel,
    id: String(funnel.id),
    stages: Object.entries(funnel.stages || {}).reduce(
      (acc, [key, stage]) => ({
        ...acc,
        [String(key)]: {
          ...stage,
          id: String(stage.id || key),
        },
      }),
      {}
    ),
  };
  showEditModal.value = true;
};

const handleEdit = async updatedFunnel => {
  try {
    loading.value = true;
    const formattedFunnel = {
      ...updatedFunnel,
      id: String(updatedFunnel.id),
      stages: Object.entries(updatedFunnel.stages || {}).reduce(
        (acc, [key, stage]) => ({
          ...acc,
          [String(key)]: {
            ...stage,
            id: String(stage.id || key),
          },
        }),
        {}
      ),
    };
    await FunnelAPI.update(funnelToEdit.value.id, formattedFunnel);
    await store.dispatch('funnel/fetch');
    await fetchFunnels();
    showEditModal.value = false;
    funnelToEdit.value = null;
  } catch (error) {
    console.error('Erro ao atualizar funil:', error);
  } finally {
    loading.value = false;
  }
};

const emit = defineEmits(['switch-view']);

onMounted(() => {
  fetchFunnels();
});
</script>

<template>
  <div class="flex flex-col h-full bg-white dark:bg-slate-900">
    <FunnelsHeader
      @switch-view="view => emit('switch-view', view)"
      @funnel-created="fetchFunnels"
    />

    <div class="funnels-content p-6 flex-1 overflow-y-auto">
      <!-- Loading State -->
      <div v-if="loading" class="flex justify-center items-center py-12">
        <span class="loading-spinner" />
      </div>

      <!-- Empty State -->
      <div
        v-else-if="!funnels.length"
        class="flex flex-col items-center justify-center py-12 text-slate-600"
      >
        <fluent-icon icon="task" size="48" class="mb-4" />
        <p class="text-lg">{{ t('KANBAN.FUNNELS.EMPTY') }}</p>
        <p class="text-sm">{{ t('KANBAN.FUNNELS.EMPTY_DESCRIPTION') }}</p>
      </div>

      <!-- Funnels List -->
      <div v-else class="grid gap-4">
        <div
          v-for="funnel in funnels"
          :key="funnel.id"
          class="funnel-card p-4 border rounded-lg hover:bg-slate-50 dark:hover:bg-slate-800"
        >
          <div class="flex items-center justify-between">
            <div>
              <h3 class="text-lg font-medium">{{ funnel.name }}</h3>
              <p class="text-sm text-slate-600 dark:text-slate-400">
                {{ funnel.description }}
              </p>
              <div class="flex flex-wrap gap-2 mt-2">
                <span
                  v-for="(stage, id) in funnel.stages"
                  :key="id"
                  class="px-2 py-1 text-xs font-medium rounded-full"
                  :style="{
                    backgroundColor: `${stage.color}20`,
                    color: stage.color,
                  }"
                >
                  {{ stage.name }}
                </span>
              </div>
            </div>
            <div class="flex items-center gap-2">
              <woot-button
                variant="clear"
                size="small"
                @click="startEdit(funnel)"
              >
                <fluent-icon icon="edit" size="16" />
              </woot-button>
              <woot-button
                variant="clear"
                size="small"
                @click="confirmDelete(funnel)"
              >
                <fluent-icon icon="delete" size="16" />
              </woot-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Edição -->
    <Modal
      v-model:show="showEditModal"
      :on-close="() => (showEditModal = false)"
      class="funnel-modal"
    >
      <div class="p-6">
        <h3 class="text-lg font-medium mb-4">
          {{ t('KANBAN.FUNNELS.EDIT.TITLE') }}
        </h3>
        <FunnelForm
          v-if="funnelToEdit"
          :is-editing="true"
          :initial-data="funnelToEdit"
          @saved="handleEdit"
          @close="showEditModal = false"
        />
      </div>
    </Modal>

    <!-- Modal de Confirmação de Exclusão -->
    <Modal
      v-model:show="showDeleteModal"
      :on-close="() => (showDeleteModal = false)"
    >
      <div class="p-6">
        <h3 class="text-lg font-medium mb-4">
          {{ t('KANBAN.FUNNELS.DELETE.TITLE') }}
        </h3>
        <p class="text-sm text-slate-600 mb-6">
          {{
            t('KANBAN.FUNNELS.DELETE.DESCRIPTION', {
              name: funnelToDelete?.name,
            })
          }}
        </p>
        <div class="flex justify-end gap-2">
          <woot-button
            variant="clear"
            size="small"
            @click="showDeleteModal = false"
          >
            {{ t('CANCEL') }}
          </woot-button>
          <woot-button
            variant="danger"
            size="small"
            :loading="loading"
            @click="handleDelete"
          >
            {{ t('DELETE') }}
          </woot-button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--color-border-light);
  border-top: 3px solid var(--color-woot);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.funnels-content {
  min-height: 0; // Importante para o scroll funcionar
}

:deep(.funnel-modal) {
  .modal-container {
    @apply max-w-[1024px] w-[90vw];
  }
}
</style>
