/* global axios */
import ApiClient from './ApiClient';
import store from '../store';
import webhookService from '../services/kanban/webhookService';

class KanbanAPI extends ApiClient {
  constructor() {
    super('kanban_items', { accountScoped: true });
  }

  get accountId() {
    return store.state.auth.currentUser.account_id;
  }

  getItems(funnelId) {
    const params = {
      funnel_id: funnelId || store.getters['funnel/getSelectedFunnel']?.id,
    };

    return this.get('', { params });
  }

  getItem(itemId) {
    return axios.get(`${this.url}/${itemId}`);
  }

  async createItem(data) {
    try {
      const response = await axios.post(this.url, {
        kanban_item: data,
      });

      // Notifica o webhook sobre a criação
      await webhookService.notifyItemCreated(response.data);

      return response;
    } catch (error) {
      console.error('Erro ao criar item:', error);
      throw error;
    }
  }

  async updateItem(itemId, data) {
    try {
      // Primeiro obtém o item atual para comparar mudanças
      const { data: currentItem } = await this.getItem(itemId);

      // Faz a atualização
      const response = await axios.put(`${this.url}/${itemId}`, {
        kanban_item: data,
      });

      // Identifica as mudanças comparando os dados
      const changes = {};
      Object.keys(data).forEach(key => {
        if (JSON.stringify(currentItem[key]) !== JSON.stringify(data[key])) {
          changes[key] = {
            from: currentItem[key],
            to: data[key],
          };
        }
      });

      // Se houver mudanças em item_details, compara cada campo
      if (data.item_details) {
        Object.keys(data.item_details).forEach(key => {
          if (
            JSON.stringify(currentItem.item_details?.[key]) !==
            JSON.stringify(data.item_details[key])
          ) {
            if (!changes.item_details) changes.item_details = {};
            changes.item_details[key] = {
              from: currentItem.item_details?.[key],
              to: data.item_details[key],
            };
          }
        });
      }

      // Notifica o webhook sobre a atualização
      if (Object.keys(changes).length > 0) {
        await webhookService.notifyItemUpdated(response.data, changes);
      }

      return response;
    } catch (error) {
      console.error('Erro ao atualizar item:', error);
      throw error;
    }
  }

  async deleteItem(itemId) {
    try {
      // Primeiro obtém o item atual antes de deletar
      const { data: currentItem } = await this.getItem(itemId);

      // Faz a exclusão
      const response = await axios.delete(`${this.url}/${itemId}`);

      // Notifica o webhook sobre a exclusão
      await webhookService.notifyItemDeleted(currentItem);

      return response;
    } catch (error) {
      console.error('Erro ao deletar item:', error);
      throw error;
    }
  }

  async moveToStage(itemId, funnelStage) {
    try {
      console.log('KanbanAPI - moveToStage iniciado:', { itemId, funnelStage });

      // Primeiro obtém o item atual para saber o estágio anterior
      const { data: currentItem } = await this.getItem(itemId);
      console.log('KanbanAPI - Item atual:', currentItem);
      const fromStage = currentItem.funnel_stage;

      // Faz a movimentação do item
      await axios.post(`${this.url}/${itemId}/move_to_stage`, {
        funnel_stage: funnelStage,
      });

      // Busca o item atualizado após a movimentação
      const { data: updatedItem } = await this.getItem(itemId);
      console.log('KanbanAPI - Item após movimentação:', updatedItem);

      // Notifica o webhook sobre a mudança de estágio
      await webhookService.notifyStageChange(
        updatedItem,
        fromStage,
        funnelStage
      );

      return updatedItem;
    } catch (error) {
      console.error('Erro ao mover item:', error);
      throw error;
    }
  }

  async reorderItems(positions) {
    try {
      // Primeiro obtém os itens atuais para comparação
      const currentItems = await Promise.all(
        positions.map(pos => this.getItem(pos.id))
      );

      const response = await axios.post(`${this.url}/reorder`, {
        positions,
      });

      // Prepara as mudanças de posição
      const changes = positions.map(pos => ({
        item_id: pos.id,
        old_position: currentItems.find(item => item.data.id === pos.id)?.data
          .position,
        new_position: pos.position,
      }));

      // Notifica o webhook sobre a reordenação
      await webhookService.notifyItemReordered(response.data, changes);

      return response;
    } catch (error) {
      console.error('Erro ao reordenar itens:', error);
      throw error;
    }
  }

  async startTimer(itemId) {
    try {
      const response = await this.updateItem(itemId, {
        timer_started_at: new Date().toISOString(),
      });

      // Notifica o webhook sobre o início do timer
      await webhookService.notifyTimerStarted(response.data);

      return response;
    } catch (error) {
      console.error('Erro ao iniciar timer:', error);
      throw error;
    }
  }

  async stopTimer(itemId) {
    try {
      const response = await this.updateItem(itemId, {
        timer_started_at: null,
      });

      // Calcula a duração do timer se necessário
      const duration = response.data.timer_duration;

      // Notifica o webhook sobre a parada do timer
      await webhookService.notifyTimerStopped(response.data, duration);

      return response;
    } catch (error) {
      console.error('Erro ao parar timer:', error);
      throw error;
    }
  }

  getItemByConversationId(conversationId) {
    return this.get('', {
      params: {
        'item_details.conversation_id': conversationId,
      },
    });
  }
}

export default new KanbanAPI();
