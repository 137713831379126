<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import KanbanHeader from './KanbanHeader.vue';
import KanbanAPI from '../../../../api/kanban';
import CustomContextMenu from 'dashboard/components/ui/CustomContextMenu.vue';
import SendMessageTemplate from './SendMessageTemplate.vue';
import conversationAPI from '../../../../api/inbox/conversation';
import Modal from 'dashboard/components/Modal.vue';
import KanbanItemForm from './KanbanItemForm.vue';

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const isLoading = ref(false);
const deals = ref([]);
const error = ref(null);
const searchQuery = ref('');
const activeFilters = ref(null);

const emit = defineEmits(['switch-view', 'edit', 'item-click']);

const selectedFunnel = computed(
  () => store.getters['funnel/getSelectedFunnel']
);

const showDeleteModal = ref(false);
const showContextMenu = ref(false);
const showSendMessageModal = ref(false);
const contextMenuPosition = ref({ x: 0, y: 0 });
const itemToDelete = ref(null);
const selectedDeal = ref(null);
const isDeleting = ref(false);
const conversationData = ref({});

// Adicione um ref para controlar as etapas expandidas
const expandedStages = ref(new Set()); // Começa com todas as etapas fechadas

// Adicione estas refs para controlar o modal de edição
const showEditModal = ref(false);
const itemToEdit = ref(null);

const fetchDeals = async () => {
  try {
    isLoading.value = true;
    const currentFunnel = store.getters['funnel/getSelectedFunnel'];

    if (!currentFunnel?.id) {
      console.error('Nenhum funil selecionado');
      return;
    }

    const response = await KanbanAPI.getItems(currentFunnel.id);
    deals.value = response.data.map(item => ({
      id: item.id,
      title: item.item_details.title || '',
      description: item.item_details.description || '',
      priority: item.item_details.priority || 'none',
      assignee: item.item_details.assignee || null,
      value: item.item_details.value || 0,
      stage: item.funnel_stage,
      stageName: currentFunnel.stages[item.funnel_stage]?.name || '',
      stageColor: currentFunnel.stages[item.funnel_stage]?.color || '#64748B',
      createdAt: new Date(item.created_at).toLocaleDateString(),
    }));
  } catch (error) {
    console.error('Erro ao carregar deals:', error);
    error.value = error.message;
  } finally {
    isLoading.value = false;
  }
};

// Watch para atualizar quando o funil mudar
watch(
  () => store.getters['funnel/getSelectedFunnel'],
  async newFunnel => {
    if (newFunnel) {
      await fetchDeals();
    }
  }
);

onMounted(async () => {
  if (selectedFunnel.value) {
    await fetchDeals();
  }
});

// Computed para filtrar os deals baseado na busca e filtros
const filteredDeals = computed(() => {
  let filtered = deals.value;

  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase();
    filtered = filtered.filter(
      deal =>
        deal.title.toLowerCase().includes(query) ||
        deal.description.toLowerCase().includes(query) ||
        deal.assignee?.name.toLowerCase().includes(query)
    );
  }

  if (activeFilters.value) {
    const filters = activeFilters.value;

    filtered = filtered.filter(deal => {
      let matches = true;

      if (filters.priority?.length) {
        matches = matches && filters.priority.includes(deal.priority);
      }

      if (filters.value?.min || filters.value?.max) {
        const dealValue = parseFloat(deal.value) || 0;
        if (filters.value.min) {
          matches = matches && dealValue >= parseFloat(filters.value.min);
        }
        if (filters.value.max) {
          matches = matches && dealValue <= parseFloat(filters.value.max);
        }
      }

      if (filters.agent_id) {
        matches = matches && deal.assignee?.id === filters.agent_id;
      }

      if (filters.date?.start || filters.date?.end) {
        const dealDate = new Date(deal.createdAt);
        if (filters.date.start) {
          matches = matches && dealDate >= new Date(filters.date.start);
        }
        if (filters.date.end) {
          matches = matches && dealDate <= new Date(filters.date.end);
        }
      }

      return matches;
    });
  }

  return filtered;
});

const handleSearch = query => {
  searchQuery.value = query;
};

const handleFilter = filters => {
  activeFilters.value = filters;
};

const searchResults = computed(() => {
  if (!searchQuery.value) return { total: 0, stages: {} };

  const results = {
    total: filteredDeals.value.length,
    stages: {},
  };

  filteredDeals.value.forEach(deal => {
    if (!results.stages[deal.stageName]) {
      results.stages[deal.stageName] = 0;
    }
    results.stages[deal.stageName]++;
  });

  return results;
});

// Novo computed para agrupar os deals por estágio
const groupedDeals = computed(() => {
  const groups = {};

  // Primeiro passo: criar os grupos
  filteredDeals.value.forEach(deal => {
    if (!groups[deal.stage]) {
      groups[deal.stage] = {
        id: deal.stage,
        name: deal.stageName,
        color: deal.stageColor,
        deals: [],
        totalValue: 0,
      };
    }
    groups[deal.stage].deals.push(deal);
    groups[deal.stage].totalValue += Number(deal.value) || 0;
  });

  // Segundo passo: converter para array e ordenar
  return Object.values(groups).sort((a, b) => {
    const stageA = selectedFunnel.value?.stages[a.id]?.position || 0;
    const stageB = selectedFunnel.value?.stages[b.id]?.position || 0;
    return stageA - stageB;
  });
});

defineProps({
  currentView: {
    type: String,
    default: 'list',
  },
});

// Função para carregar dados da conversa
const fetchConversationData = async deal => {
  if (!deal.item_details?.conversation_id) return;

  try {
    const response = await conversationAPI.get({});
    const conversations = response.data.data.payload;
    conversationData.value[deal.id] = conversations.find(
      c => c.id === deal.item_details.conversation_id
    );
  } catch (error) {
    console.error('Erro ao carregar dados da conversa:', error);
  }
};

// Handlers
const handleEdit = deal => {
  itemToEdit.value = {
    id: deal.id,
    title: deal.title,
    description: deal.description,
    funnel_id: selectedFunnel.value?.id,
    funnel_stage: deal.stage,
    item_details: {
      title: deal.title,
      description: deal.description,
      priority: deal.priority,
      value: deal.value,
      agent_id: deal.assignee?.id,
      conversation_id: deal.item_details?.conversation_id,
    },
    custom_attributes: deal.custom_attributes || {},
  };
  showEditModal.value = true;
};

const handleItemEdited = async updatedItem => {
  showEditModal.value = false;
  itemToEdit.value = null;
  await fetchDeals();
};

const handleDelete = deal => {
  itemToDelete.value = deal;
  showDeleteModal.value = true;
};

const confirmDelete = async () => {
  if (!itemToDelete.value) return;

  try {
    isDeleting.value = true;
    await KanbanAPI.deleteItem(itemToDelete.value.id);
    await fetchDeals();
    showDeleteModal.value = false;
    itemToDelete.value = null;
  } catch (error) {
    console.error('Erro ao excluir item:', error);
  } finally {
    isDeleting.value = false;
  }
};

const handleContextMenu = (e, deal) => {
  e.preventDefault();
  selectedDeal.value = deal;
  showContextMenu.value = true;
  contextMenuPosition.value = {
    x: e.clientX,
    y: e.clientY,
  };
};

const handleQuickMessage = () => {
  showContextMenu.value = false;
  showSendMessageModal.value = true;
};

const handleViewContact = () => {
  showContextMenu.value = false;
  const conversation = conversationData.value[selectedDeal.value.id];

  if (!conversation?.meta?.sender?.id) return;

  router.push({
    name: 'contact_profile',
    params: {
      accountId: conversation.account_id,
      contactId: conversation.meta.sender.id,
    },
    query: { page: 1 },
  });
};

const handleSendMessage = () => {
  showSendMessageModal.value = false;
  showContextMenu.value = false;
};

const handleItemClick = deal => {
  emit('item-click', deal);
};

// Watch para carregar dados da conversa quando necessário
watch(groupedDeals, async newGroups => {
  for (const group of newGroups) {
    for (const deal of group.deals) {
      if (deal.item_details?.conversation_id) {
        await fetchConversationData(deal);
      }
    }
  }
});

// Função para navegar para a conversa
const navigateToConversation = (e, conversationId) => {
  e.stopPropagation();
  if (!conversationId || !conversationData.value[selectedDeal.value.id]) return;

  try {
    const conversation = conversationData.value[selectedDeal.value.id];
    router.push({
      name: 'inbox_conversation_through_inbox',
      params: {
        accountId: conversation.account_id,
        conversationId: conversationId,
      },
    });
  } catch (error) {
    console.error('Erro ao navegar para a conversa:', error);
    // Fallback: navegação direta pela URL
    const conversation = conversationData.value[selectedDeal.value.id];
    window.location.href = `/app/accounts/${conversation.account_id}/conversations/${conversationId}`;
  }
};

// Função para alternar expansão da etapa
const toggleStage = stageId => {
  if (expandedStages.value.has(stageId)) {
    expandedStages.value.delete(stageId);
  } else {
    expandedStages.value.add(stageId);
  }
};

// Função para verificar se uma etapa está expandida
const isStageExpanded = stageId => expandedStages.value.has(stageId);
</script>

<template>
  <div class="flex flex-col h-full w-full bg-white dark:bg-slate-900">
    <KanbanHeader
      :current-view="'list'"
      :search-results="searchResults"
      :active-filters="activeFilters"
      @filter="handleFilter"
      @search="handleSearch"
      @switch-view="view => emit('switch-view', view)"
    />

    <div class="deals-list p-4 flex-1 overflow-auto w-full">
      <!-- Loading State -->
      <div v-if="isLoading" class="flex justify-center items-center py-12">
        <span class="loading-spinner" />
      </div>

      <!-- Error State -->
      <div
        v-else-if="error"
        class="flex justify-center items-center py-12 text-ruby-500"
      >
        {{ error }}
      </div>

      <!-- Empty State -->
      <div
        v-else-if="!filteredDeals.length"
        class="flex flex-col items-center justify-center py-12"
      >
        <fluent-icon icon="task" size="48" class="mb-4 text-slate-400" />
        <p class="text-lg text-slate-600">{{ t('KANBAN.NO_DEALS') }}</p>
      </div>

      <!-- Deals List Grouped -->
      <div v-else class="space-y-4 w-full max-w-full">
        <div
          v-for="stage in groupedDeals"
          :key="stage.id"
          class="deal-group w-full"
        >
          <!-- Stage Header -->
          <div
            class="stage-header p-3 flex items-center justify-between bg-slate-50 dark:bg-slate-800 rounded-lg cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-700 transition-colors"
            :class="{ 'rounded-b-none': isStageExpanded(stage.id) }"
            @click="toggleStage(stage.id)"
          >
            <div class="flex items-center gap-3">
              <fluent-icon
                :icon="
                  isStageExpanded(stage.id) ? 'chevron-down' : 'chevron-right'
                "
                size="16"
                class="text-slate-500 transition-transform"
                :class="{ 'transform rotate-90': !isStageExpanded(stage.id) }"
              />
              <div class="flex items-center gap-2">
                <span
                  class="w-2 h-2 rounded-full"
                  :style="{ backgroundColor: stage.color }"
                />
                <h3
                  class="text-lg font-medium text-slate-800 dark:text-slate-200"
                >
                  {{ stage.name }}
                </h3>
                <span
                  class="px-2 py-0.5 text-xs font-medium rounded-full"
                  :style="{
                    backgroundColor: `${stage.color}15`,
                    color: stage.color,
                  }"
                >
                  {{ stage.deals.length }}
                </span>
              </div>
            </div>
            <div class="flex items-center gap-4">
              <div
                class="text-sm font-medium text-slate-700 dark:text-slate-300"
              >
                {{
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(stage.totalValue)
                }}
              </div>
              <div
                class="w-6 h-6 flex items-center justify-center rounded-full bg-slate-200 dark:bg-slate-700"
                :class="{ 'rotate-180': isStageExpanded(stage.id) }"
              >
                <fluent-icon
                  icon="chevron-down"
                  size="16"
                  class="text-slate-600 dark:text-slate-400 transition-transform"
                />
              </div>
            </div>
          </div>

          <!-- Deals Table -->
          <div
            v-show="isStageExpanded(stage.id)"
            class="overflow-hidden border border-t-0 border-slate-200 dark:border-slate-700 rounded-b-lg transition-all"
          >
            <table class="w-full">
              <tbody>
                <tr
                  v-for="deal in stage.deals"
                  :key="deal.id"
                  class="group border-b last:border-b-0 dark:border-slate-700/50 hover:bg-slate-50 dark:hover:bg-slate-800/50 transition-colors cursor-pointer"
                  @click="handleItemClick(deal)"
                >
                  <td class="py-3 px-4">
                    <div class="flex items-center gap-3">
                      <div class="flex-1">
                        <div class="flex items-center gap-2">
                          <div
                            class="font-medium text-slate-900 dark:text-slate-100"
                          >
                            {{ deal.title }}
                          </div>
                          <!-- Botões de ação -->
                          <div
                            class="flex items-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity"
                          >
                            <woot-button
                              variant="clear"
                              size="small"
                              class="p-1 text-slate-600 dark:text-slate-400 hover:text-slate-900 dark:hover:text-slate-100"
                              @click.stop="handleEdit(deal)"
                            >
                              <fluent-icon icon="edit" size="14" />
                            </woot-button>
                            <woot-button
                              variant="clear"
                              size="small"
                              class="p-1 text-slate-600 dark:text-slate-400 hover:text-ruby-600 dark:hover:text-ruby-400"
                              @click.stop="handleDelete(deal)"
                            >
                              <fluent-icon icon="delete" size="14" />
                            </woot-button>
                          </div>
                        </div>
                        <!-- Informações da Conversa -->
                        <div
                          v-if="
                            deal.item_details?.conversation_id &&
                            conversationData[deal.id]
                          "
                          class="mt-1 flex items-center gap-2 p-1.5 bg-slate-50 dark:bg-slate-800 rounded border border-slate-100 dark:border-slate-700 text-xs hover:bg-slate-100 dark:hover:bg-slate-700 transition-colors"
                          @click.stop="
                            navigateToConversation(
                              $event,
                              deal.item_details.conversation_id
                            )
                          "
                          @contextmenu.prevent="handleContextMenu($event, deal)"
                        >
                          <fluent-icon
                            icon="chat"
                            class="text-slate-500 dark:text-slate-400"
                            size="12"
                          />
                          <span class="text-slate-700 dark:text-slate-300">
                            #{{ conversationData[deal.id].id }} -
                            {{
                              conversationData[deal.id].meta.sender.name ||
                              conversationData[deal.id].meta.sender.email ||
                              t('KANBAN.CONVERSATION_NO_CONTACT')
                            }}
                          </span>
                          <span
                            v-if="conversationData[deal.id].unread_count > 0"
                            class="flex items-center justify-center h-4 min-w-[1rem] px-1 text-[0.625rem] font-medium bg-ruby-500 text-white rounded-full"
                          >
                            {{
                              conversationData[deal.id].unread_count > 9
                                ? '9+'
                                : conversationData[deal.id].unread_count
                            }}
                          </span>
                        </div>
                        <div
                          v-if="deal.description"
                          class="text-sm text-slate-500 dark:text-slate-400 line-clamp-1"
                        >
                          {{ deal.description }}
                        </div>
                      </div>
                      <div class="flex items-center gap-6">
                        <div class="text-right">
                          <div
                            class="text-sm font-medium text-slate-900 dark:text-slate-100"
                          >
                            {{
                              new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(deal.value)
                            }}
                          </div>
                          <div class="text-xs text-slate-500">
                            {{ deal.createdAt }}
                          </div>
                        </div>
                        <div
                          v-if="deal.assignee"
                          class="flex items-center gap-2 min-w-[120px]"
                        >
                          <span
                            v-if="!deal.assignee.avatar"
                            class="w-8 h-8 rounded-full bg-slate-200 flex items-center justify-center text-sm font-medium text-slate-600"
                          >
                            {{ deal.assignee.name.charAt(0) }}
                          </span>
                          <img
                            v-else
                            :src="deal.assignee.avatar"
                            :alt="deal.assignee.name"
                            class="w-8 h-8 rounded-full"
                          />
                          <div class="flex flex-col">
                            <span
                              class="text-sm font-medium text-slate-700 dark:text-slate-200"
                            >
                              {{ deal.assignee.name }}
                            </span>
                            <span class="text-xs text-slate-500">
                              {{ t('KANBAN.OWNER') }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modais e Menus de Contexto -->
  <Modal
    v-model:show="showDeleteModal"
    :on-close="
      () => {
        showDeleteModal = false;
        itemToDelete = null;
      }
    "
  >
    <div class="p-6">
      <h3 class="text-lg font-medium mb-4">
        {{ t('KANBAN.DELETE_CONFIRMATION.TITLE') }}
      </h3>
      <p class="text-sm text-slate-600 mb-6">
        {{
          t('KANBAN.DELETE_CONFIRMATION.MESSAGE', {
            item: itemToDelete?.title,
          })
        }}
      </p>
      <div class="flex justify-end gap-2">
        <woot-button
          variant="clear"
          size="small"
          @click="showDeleteModal = false"
        >
          {{ t('CANCEL') }}
        </woot-button>
        <woot-button
          variant="danger"
          size="small"
          :loading="isDeleting"
          @click="confirmDelete"
        >
          {{ t('DELETE') }}
        </woot-button>
      </div>
    </div>
  </Modal>

  <CustomContextMenu
    v-if="showContextMenu"
    :x="contextMenuPosition.x"
    :y="contextMenuPosition.y"
    :show="showContextMenu"
    @close="showContextMenu = false"
  >
    <div
      class="bg-white dark:bg-slate-800 rounded-lg shadow-lg border border-slate-200 dark:border-slate-700 py-1 w-[180px]"
    >
      <button
        class="w-full px-4 py-2 text-left text-sm hover:bg-slate-50 dark:hover:bg-slate-700 text-slate-700 dark:text-slate-200"
        @click.stop="handleQuickMessage"
      >
        <span class="flex items-center gap-2">
          <fluent-icon icon="chat" size="16" />
          {{ t('KANBAN.CONTEXT_MENU.QUICK_MESSAGE') }}
        </span>
      </button>
      <button
        class="w-full px-4 py-2 text-left text-sm hover:bg-slate-50 dark:hover:bg-slate-700 text-slate-700 dark:text-slate-200"
        @click="handleViewContact"
      >
        <span class="flex items-center gap-2">
          <fluent-icon icon="person" size="16" />
          {{ t('KANBAN.CONTEXT_MENU.VIEW_CONTACT') }}
        </span>
      </button>
    </div>
  </CustomContextMenu>

  <Modal
    v-model:show="showSendMessageModal"
    :on-close="() => (showSendMessageModal = false)"
    :show-close-button="true"
    size="medium"
  >
    <SendMessageTemplate
      v-if="selectedDeal"
      :conversation-id="selectedDeal.item_details?.conversation_id"
      :current-stage="selectedDeal.stage"
      :contact="conversationData[selectedDeal.id]?.meta?.sender"
      :conversation="conversationData[selectedDeal.id]"
      :item="selectedDeal"
      @close="showSendMessageModal = false"
      @send="handleSendMessage"
    />
  </Modal>

  <!-- Adicione o modal de edição -->
  <Modal
    v-model:show="showEditModal"
    :on-close="
      () => {
        showEditModal = false;
        itemToEdit = null;
      }
    "
  >
    <div class="p-6">
      <h3 class="text-lg font-medium mb-4">
        {{ t('KANBAN.EDIT_ITEM') }}
      </h3>
      <KanbanItemForm
        v-if="itemToEdit && selectedFunnel"
        :funnel-id="selectedFunnel.id"
        :stage="itemToEdit.funnel_stage"
        :initial-data="itemToEdit"
        :is-editing="true"
        @saved="handleItemEdited"
        @close="showEditModal = false"
      />
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.deals-list {
  min-height: 0;
  width: 100%;
  max-width: 100%;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-woot);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.deal-group {
  @apply animate-fadeIn w-full;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-out forwards;
}

// Adiciona delay na animação para cada grupo
.deal-group:nth-child(1) {
  animation-delay: 0s;
}
.deal-group:nth-child(2) {
  animation-delay: 0.1s;
}
.deal-group:nth-child(3) {
  animation-delay: 0.2s;
}
.deal-group:nth-child(4) {
  animation-delay: 0.3s;
}
.deal-group:nth-child(5) {
  animation-delay: 0.4s;
}

.group {
  &:hover {
    .opacity-0 {
      opacity: 1;
    }
  }
}

// Animação para o ícone de expansão
.transform {
  transition: transform 0.2s ease;
}

// Animação para o conteúdo expandido
.transition-all {
  transition: all 0.3s ease-in-out;
}
</style>
