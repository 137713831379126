<script setup>
import { computed, ref, onMounted, watch, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import Avatar from 'dashboard/components-next/avatar/Avatar.vue';
import agents from '../../../../api/agents';
import conversationAPI from '../../../../api/inbox/conversation';
import CustomContextMenu from 'dashboard/components/ui/CustomContextMenu.vue';
import Modal from 'dashboard/components/Modal.vue';
import SendMessageTemplate from './SendMessageTemplate.vue';

const router = useRouter();

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  isDragging: {
    type: Boolean,
    default: false,
  },
  draggable: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['click', 'edit', 'delete', 'dragstart']);

const { t } = useI18n();

// Ref para armazenar os dados do agente
const agentData = ref(null);
const loadingAgent = ref(false);

// Função para buscar dados do agente
const fetchAgentData = async () => {
  const agentId = props.item.item_details?.agent_id;
  if (!agentId) return;

  try {
    loadingAgent.value = true;
    const { data } = await agents.get();
    // Encontra o agente específico pelo ID
    agentData.value = data.find(agent => agent.id === agentId);
  } catch (error) {
    console.error('Erro ao carregar dados do agente:', error);
  } finally {
    loadingAgent.value = false;
  }
};

// Computed para formatar os dados do agente
const agentInfo = computed(() => {
  if (!agentData.value) return null;

  return {
    name: agentData.value.name,
    avatar_url: agentData.value.thumbnail,
  };
});

// Ref para armazenar os dados da conversa
const conversationData = ref(null);
const loadingConversation = ref(false);

// Função para buscar dados da conversa
const fetchConversationData = async () => {
  const conversationId = props.item.item_details?.conversation_id;
  if (!conversationId) return;

  try {
    loadingConversation.value = true;
    const response = await conversationAPI.get({});
    const conversations = response.data.data.payload;
    conversationData.value = conversations.find(c => c.id === conversationId);
  } catch (error) {
    console.error('Erro ao carregar dados da conversa:', error);
  } finally {
    loadingConversation.value = false;
  }
};

// Carregar dados do agente quando o componente for montado
onMounted(() => {
  fetchAgentData();
  fetchConversationData();
});

// Watch para atualizar os dados do agente quando o item mudar
watch(
  () => props.item.item_details?.agent_id,
  newAgentId => {
    if (newAgentId) {
      fetchAgentData();
    } else {
      agentData.value = null;
    }
  }
);

const priorityClass = computed(() => {
  const priorityMap = {
    high: 'bg-ruby-50 dark:bg-ruby-800 text-ruby-800 dark:text-ruby-50',
    medium:
      'bg-yellow-50 dark:bg-yellow-800 text-yellow-800 dark:text-yellow-50',
    low: 'bg-green-50 dark:bg-green-800 text-green-800 dark:text-green-50',
    none: 'bg-slate-50 dark:bg-slate-800 text-slate-800 dark:text-slate-50',
  };
  return priorityMap[props.item.priority] || priorityMap.none;
});

const formattedDate = computed(() => {
  // Usar created_at se disponível, caso contrário usar createdAt ou data atual
  const dateStr =
    props.item.created_at || props.item.createdAt || new Date().toISOString();
  try {
    return t('KANBAN.CREATED_AT_FORMAT', {
      date: new Date(dateStr).toLocaleDateString(),
    });
  } catch (error) {
    console.error('Erro ao formatar data:', error);
    return t('KANBAN.CREATED_AT_FORMAT', {
      date: new Date().toLocaleDateString(),
    });
  }
});

const truncatedTitle = computed(() => {
  const titleValue = props.item.title || '';
  if (titleValue.length > 20) {
    return `${titleValue.substring(0, 20)}...`;
  }
  return titleValue;
});

const truncatedSenderName = computed(() => {
  const name = conversationData.value?.meta?.sender?.name;
  const email = conversationData.value?.meta?.sender?.email;
  const defaultText = t('KANBAN.CONVERSATION_NO_CONTACT');
  const text = name || email || defaultText;

  return text.length > 25 ? `${text.substring(0, 25)}...` : text;
});

const handleClick = () => {
  emit('click', props.item);
};

const handleEdit = e => {
  e.stopPropagation();
  emit('edit', props.item);
};

const handleDelete = e => {
  e.stopPropagation();
  emit('delete', props.item);
};

// Função para navegar para a conversa
const navigateToConversation = (e, conversationId) => {
  e.stopPropagation(); // Previne a propagação do clique para o card
  if (!conversationId || !conversationData.value) return;

  try {
    router.push({
      name: 'inbox_conversation_through_inbox',
      params: {
        accountId: conversationData.value.account_id,
        conversationId: conversationId,
      },
    });
  } catch (error) {
    console.error('Erro ao navegar para a conversa:', error);
    // Fallback: navegação direta pela URL
    window.location.href = `/app/accounts/${conversationData.value.account_id}/conversations/${conversationId}`;
  }
};

// Adicione essas novas refs e funções
const showContextMenu = ref(false);
const contextMenuPosition = ref({ x: 0, y: 0 });

const handleContextMenu = (e, conversationId) => {
  if (!conversationId || !conversationData.value) return;

  e.preventDefault();
  console.log('1. Context Menu aberto');
  showContextMenu.value = true;
  contextMenuPosition.value = {
    x: e.clientX,
    y: e.clientY,
  };
};

const showSendMessageModal = ref(false);

const handleQuickMessage = () => {
  console.log('2. Clicou em Mensagem Rápida');
  try {
    console.log('2.1 Tentando processar clique');
    nextTick(() => {
      showContextMenu.value = false;
      showSendMessageModal.value = true;
    });
    console.log('3. Context Menu fechado:', showContextMenu.value);
    console.log('4. Modal definido como aberto:', showSendMessageModal.value);
  } catch (error) {
    console.error('Erro ao processar clique:', error);
  }
};

const handleSendMessage = ({ template, conversationId }) => {
  console.log('6. Enviando mensagem');
  console.log('Template selecionado:', template);
  console.log('ID da conversa:', conversationId);
  showSendMessageModal.value = false;
  console.log('7. Modal fechado após envio:', showSendMessageModal);
};

const handleViewContact = () => {
  // Fecha o menu de contexto
  showContextMenu.value = false;

  // Verifica se temos os dados necessários
  if (!conversationData.value?.meta?.sender?.id) {
    return;
  }

  try {
    // Navega para a página do contato
    router.push({
      name: 'contact_profile',
      params: {
        accountId: conversationData.value.account_id,
        contactId: conversationData.value.meta.sender.id,
      },
      query: {
        page: 1,
      },
    });
  } catch (err) {
    // Fallback: navegação direta pela URL
    window.location.href = `/app/accounts/${conversationData.value.account_id}/contacts/${conversationData.value.meta.sender.id}?page=1`;
  }
};

const handleDragStart = e => {
  emit('dragstart', e);
};

// Adicionar watch para monitorar mudanças
watch(showSendMessageModal, newValue => {
  console.log('Modal status alterado:', newValue);
  if (!newValue) {
    nextTick(() => {
      showContextMenu.value = false;
    });
  }
});
</script>

<template>
  <div
    class="flex flex-col p-4 bg-white dark:bg-slate-900 rounded-lg border border-slate-100 dark:border-slate-800 shadow-sm hover:shadow-md transition-shadow cursor-pointer mt-3"
    :class="{ 'opacity-50': isDragging }"
    :draggable="draggable"
    @dragstart="handleDragStart"
    @click="handleClick"
  >
    <div class="flex items-center justify-between mb-3">
      <div class="flex items-center gap-2">
        <h3 class="text-sm font-medium text-slate-900 dark:text-slate-100">
          {{ truncatedTitle }}
        </h3>
        <span
          class="px-2 py-1 text-xs font-medium rounded-full"
          :class="priorityClass"
        >
          {{ t(`PRIORITY_LABELS.${(item.priority || 'none').toUpperCase()}`) }}
        </span>
      </div>
      <div class="flex items-center gap-2">
        <button
          class="p-1 text-slate-600 dark:text-slate-400 hover:text-slate-900 dark:hover:text-slate-100"
          @click="handleEdit"
        >
          <fluent-icon icon="edit" size="16" />
        </button>
        <button
          class="p-1 text-slate-600 dark:text-slate-400 hover:text-ruby-600 dark:hover:text-ruby-400"
          @click="handleDelete"
        >
          <fluent-icon icon="delete" size="16" />
        </button>
      </div>
    </div>

    <p class="text-sm text-slate-600 dark:text-slate-400 mb-3">
      {{ item.description || t('KANBAN.NO_DESCRIPTION') }}
    </p>

    <!-- Informação da Conversa -->
    <div
      v-if="item.item_details?.conversation_id && conversationData"
      class="flex items-center gap-2 mb-3 p-2 bg-slate-50 dark:bg-slate-800 rounded-lg border border-slate-100 dark:border-slate-700 min-h-[1.5rem] cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-700 transition-colors"
      @click="navigateToConversation($event, item.item_details.conversation_id)"
      @contextmenu="
        handleContextMenu($event, item.item_details.conversation_id)
      "
    >
      <div class="flex items-center gap-2 flex-1">
        <fluent-icon
          icon="chat"
          class="text-slate-500 dark:text-slate-400 flex-shrink-0"
          size="16"
        />
        <div
          v-if="conversationData"
          class="flex items-center justify-between w-full"
        >
          <p class="text-xs text-slate-700 dark:text-slate-300 truncate my-0">
            #{{ conversationData.id }} - {{ truncatedSenderName }}
          </p>
          <span
            v-if="conversationData.unread_count > 0"
            class="flex items-center justify-center h-4 min-w-[1rem] px-1 text-[0.625rem] font-medium bg-ruby-500 text-white rounded-full flex-shrink-0"
          >
            {{
              conversationData.unread_count > 9
                ? '9+'
                : conversationData.unread_count
            }}
          </span>
        </div>
        <div
          v-else-if="loadingConversation"
          class="text-xs text-slate-500 flex items-center"
        >
          {{ t('KANBAN.LOADING_CONVERSATION') }}
        </div>
        <div v-else class="text-xs text-slate-500 flex items-center">
          {{ t('KANBAN.CONVERSATION_NOT_FOUND') }}
        </div>
      </div>
    </div>

    <div class="flex items-center justify-between">
      <div class="flex items-center gap-2">
        <div class="flex items-center gap-2">
          <Avatar
            v-if="agentInfo"
            :name="agentInfo.name"
            :src="agentInfo.avatar_url"
            :size="24"
          />
          <span
            v-if="agentInfo"
            class="text-xs text-slate-600 dark:text-slate-400"
          >
            {{ agentInfo.name }}
          </span>
          <span v-else-if="loadingAgent" class="text-xs text-slate-400">
            {{ t('KANBAN.LOADING_AGENT') }}
          </span>
          <span v-else class="text-xs text-slate-400 dark:text-slate-600">
            {{ t('KANBAN.NO_AGENT_ASSIGNED') }}
          </span>
        </div>
      </div>
      <span class="text-xs text-slate-500 dark:text-slate-400">
        {{ formattedDate }}
      </span>
    </div>
  </div>

  <!-- Adicionar o ContextMenu -->
  <CustomContextMenu
    v-if="showContextMenu"
    :x="contextMenuPosition.x"
    :y="contextMenuPosition.y"
    :show="showContextMenu"
    @close="showContextMenu = false"
  >
    <div
      class="bg-white dark:bg-slate-800 rounded-lg shadow-lg border border-slate-200 dark:border-slate-700 py-1 w-[180px]"
    >
      <button
        class="w-full px-4 py-2 text-left text-sm hover:bg-slate-50 dark:hover:bg-slate-700 text-slate-700 dark:text-slate-200"
        @click.stop="handleQuickMessage"
        @mousedown.stop
        @mouseup.stop
      >
        <span class="flex items-center gap-2">
          <fluent-icon icon="chat" size="16" />
          {{ t('KANBAN.CONTEXT_MENU.QUICK_MESSAGE') }}
        </span>
      </button>
      <button
        class="w-full px-4 py-2 text-left text-sm hover:bg-slate-50 dark:hover:bg-slate-700 text-slate-700 dark:text-slate-200"
        @click="handleViewContact"
      >
        <span class="flex items-center gap-2">
          <fluent-icon icon="person" size="16" />
          {{ t('KANBAN.CONTEXT_MENU.VIEW_CONTACT') }}
        </span>
      </button>
    </div>
  </CustomContextMenu>

  <!-- Modal de Mensagem Rápida -->
  <Modal
    v-model:show="showSendMessageModal"
    :on-close="() => (showSendMessageModal = false)"
    :show-close-button="true"
    size="medium"
    :close-on-backdrop-click="false"
    :class="{ 'z-50': showSendMessageModal }"
  >
    <div class="settings-modal">
      <header class="settings-header">
        <h3 class="text-lg font-medium">
          {{ t('KANBAN.SEND_MESSAGE.TITLE') }}
        </h3>
      </header>

      <div class="settings-content">
        <SendMessageTemplate
          :conversation-id="item.item_details?.conversation_id"
          :current-stage="item.funnel_stage || ''"
          :contact="conversationData?.meta?.sender"
          :conversation="conversationData"
          :item="item"
          @close="
            () => {
              showSendMessageModal = false;
              showContextMenu.value = false;
            }
          "
          @send="
            data => {
              console.log('SendMessageTemplate @send');
              handleSendMessage(data);
            }
          "
        />
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.flex {
  &:first-child {
    margin-top: 0;
  }
}

.settings-modal {
  @apply flex flex-col;

  .settings-header {
    @apply p-4 border-b border-slate-100 dark:border-slate-700;
  }

  .settings-content {
    @apply p-4 space-y-4;
  }
}
</style>
