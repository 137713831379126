<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import PriorityIcon from 'dashboard/routes/dashboard/inbox/components/PriorityIcon.vue';
import Avatar from 'dashboard/components-next/avatar/Avatar.vue';
import agents from '../../../../api/agents';
import conversationAPI from '../../../../api/inbox/conversation';
import KanbanAPI from '../../../../api/kanban';
import contacts from '../../../../api/contacts';
import { useStore } from 'vuex';

// Definição dos props e emits
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:item', 'close', 'edit', 'item-updated']);

const { t } = useI18n();
const store = useStore();

// Refs para itens vinculados
const showItemSelector = ref(false);
const showConversationSelector = ref(false);
const showContactSelector = ref(false);
const kanbanItems = ref([]);
const conversations = ref([]);
const contactsList = ref([]);
const loadingItems = ref(false);
const loadingConversations = ref(false);
const loadingContacts = ref(false);
const selectedItemId = ref(null);
const selectedConversationId = ref(null);
const selectedContactId = ref(null);
const isNotesExpanded = ref(false);

// Refs para o checklist
const newChecklistItem = ref('');
const hideCompletedItems = ref(false);

// Computed properties
const checklistItems = ref(props.item.item_details?.checklist || []);

const filteredChecklistItems = computed(() => {
  if (hideCompletedItems.value) {
    return checklistItems.value.filter(item => !item.completed);
  }
  return checklistItems.value;
});

const totalItems = computed(() => checklistItems.value.length);
const completedItems = computed(
  () => checklistItems.value.filter(item => item.completed).length
);

const checklistProgress = computed(() => {
  if (totalItems.value === 0) return 0;
  return (completedItems.value / totalItems.value) * 100;
});

// Função para buscar itens do kanban
const fetchKanbanItems = async () => {
  try {
    const currentFunnel = store.getters['funnel/getSelectedFunnel'];
    if (!currentFunnel?.id) return;

    loadingItems.value = true;
    const response = await KanbanAPI.getItems(currentFunnel.id);

    // Formata os itens para o selector
    kanbanItems.value = response.data
      .filter(item => item.id !== props.item.id) // Remove o item atual da lista
      .map(item => ({
        id: item.id,
        title: item.item_details.title || '',
        description: item.item_details.description || '',
        priority: item.item_details.priority || 'none',
        funnel_stage: item.funnel_stage,
        stage_name:
          store.getters['funnel/getSelectedFunnel'].stages[item.funnel_stage]
            ?.name || '',
        createdAt: new Date(item.created_at).toLocaleDateString(),
      }));
  } catch (error) {
    console.error('Erro ao carregar itens:', error);
  } finally {
    loadingItems.value = false;
  }
};

const priorityClass = computed(() => {
  const priorityMap = {
    high: 'bg-ruby-50 dark:bg-ruby-800 text-ruby-800 dark:text-ruby-50',
    medium:
      'bg-yellow-50 dark:bg-yellow-800 text-yellow-800 dark:text-yellow-50',
    low: 'bg-green-50 dark:bg-green-800 text-green-800 dark:text-green-50',
    none: 'bg-slate-50 dark:bg-slate-800 text-slate-800 dark:text-slate-50',
  };
  return priorityMap[props.item.priority] || priorityMap.none;
});

const formattedValue = computed(() => {
  if (!props.item.item_details?.value) return null;
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(props.item.item_details.value);
});

// Ref para armazenar os dados do agente
const agentData = ref(null);
const loadingAgent = ref(false);

// Função para buscar dados do agente
const fetchAgentData = async () => {
  const agentId = props.item.item_details?.agent_id;
  if (!agentId) return;

  try {
    loadingAgent.value = true;
    const { data } = await agents.get();
    agentData.value = data.find(agent => agent.id === agentId);
  } catch (error) {
    console.error('Erro ao carregar dados do agente:', error);
  } finally {
    loadingAgent.value = false;
  }
};

// Computed para formatar os dados do agente
const agentInfo = computed(() => {
  if (!agentData.value) return null;

  return {
    name: agentData.value.name,
    avatar_url: agentData.value.thumbnail,
  };
});

// Ref para armazenar os dados da conversa
const conversationData = ref(null);
const loadingConversation = ref(false);

// Função para buscar dados da conversa
const fetchConversationData = async () => {
  const conversationId = props.item.item_details?.conversation_id;
  if (!conversationId) return;

  try {
    loadingConversation.value = true;
    const response = await conversationAPI.get({});
    const conversations = response.data.data.payload;
    conversationData.value = conversations.find(c => c.id === conversationId);
  } catch (error) {
    console.error('Erro ao carregar dados da conversa:', error);
  } finally {
    loadingConversation.value = false;
  }
};

// Refs para as notas
const currentNote = ref('');
const savingNotes = ref(false);
const notes = ref(
  Array.isArray(props.item.item_details?.notes)
    ? props.item.item_details.notes
    : []
);
const editingNoteId = ref(null);
const editingNoteContent = ref('');

// Função para buscar conversas
const fetchConversations = async () => {
  try {
    loadingConversations.value = true;
    const response = await conversationAPI.get({});

    conversations.value = response.data.data.payload.map(conversation => ({
      id: conversation.id,
      title:
        conversation.meta.sender.name ||
        conversation.meta.sender.email ||
        t('KANBAN.CONVERSATION_NO_CONTACT'),
      description: conversation.messages[0]?.content || t('KANBAN.NO_MESSAGES'),
      unread_count: conversation.unread_count,
      created_at: new Date(conversation.created_at).toLocaleDateString(),
      channel_type: conversation.channel_type,
      status: conversation.status,
    }));
  } catch (error) {
    console.error('Erro ao carregar conversas:', error);
  } finally {
    loadingConversations.value = false;
  }
};

// Função para selecionar uma conversa
const selectConversation = conversation => {
  selectedConversationId.value = conversation.id;
  showConversationSelector.value = false;
};

// Função para buscar contatos
const fetchContacts = async () => {
  try {
    loadingContacts.value = true;
    const response = await contacts.get(1, 'name');

    contactsList.value =
      response.data?.payload?.map(contact => ({
        id: contact.id,
        name: contact.name || contact.email || t('KANBAN.FORM.NOTES.NO_NAME'),
        email: contact.email,
        phone: contact.phone_number,
        avatar_url: contact.thumbnail,
        created_at: new Date(contact.created_at * 1000).toLocaleDateString(),
        last_activity_at: contact.last_activity_at
          ? new Date(contact.last_activity_at * 1000).toLocaleDateString()
          : null,
        availability_status: contact.availability_status,
        custom_attributes: contact.custom_attributes || {},
        additional_attributes: contact.additional_attributes || {},
      })) || [];
  } catch (error) {
    console.error('Erro ao carregar contatos:', error);
  } finally {
    loadingContacts.value = false;
  }
};

// Função para selecionar um contato
const selectContact = contact => {
  selectedContactId.value = contact.id;
  showContactSelector.value = false;
};

// Função para adicionar uma nova nota
const addNote = async () => {
  if (!currentNote.value.trim()) return;

  try {
    savingNotes.value = true;

    let linkedConversationData = null;
    let linkedContactData = null;

    if (selectedConversationId.value) {
      linkedConversationData = await fetchNoteConversationData(
        selectedConversationId.value
      );
    }

    if (selectedContactId.value) {
      linkedContactData = await fetchNoteContactData(selectedContactId.value);
    }

    const newNote = {
      id: Date.now(),
      content: currentNote.value,
      created_at: new Date().toISOString(),
      agent: agentData.value?.name || 'Sistema',
      linked_item_id: selectedItemId.value,
      linked_conversation_id: selectedConversationId.value,
      linked_contact_id: selectedContactId.value,
      conversation_data: linkedConversationData,
      contact_data: linkedContactData,
    };

    const updatedNotes = [...notes.value, newNote];

    const payload = {
      funnel_id: props.item.funnel_id,
      funnel_stage: props.item.funnel_stage,
      position: props.item.position,
      item_details: {
        ...props.item.item_details,
        notes: updatedNotes,
      },
    };

    await KanbanAPI.updateItem(props.item.id, payload);
    notes.value = updatedNotes;
    currentNote.value = '';

    // Limpar seleções após salvar
    selectedItemId.value = null;
    selectedConversationId.value = null;
    selectedContactId.value = null;

    // Emitir evento de atualização
    emit('item-updated');
  } catch (error) {
    console.error('Erro ao salvar a nota:', error);
  } finally {
    savingNotes.value = false;
  }
};

// Formatar data
const formatDate = dateString => {
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '(Data inválida)';
    }

    return new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(dateString));
  } catch (error) {
    console.error('Erro ao formatar data:', error);
    return '(Data inválida)';
  }
};

// Função para remover uma nota
const removeNote = async noteId => {
  try {
    savingNotes.value = true;
    const updatedNotes = notes.value.filter(note => note.id !== noteId);

    const payload = {
      funnel_id: props.item.funnel_id,
      funnel_stage: props.item.funnel_stage,
      position: props.item.position,
      item_details: {
        ...props.item.item_details,
        notes: updatedNotes,
      },
    };

    await KanbanAPI.updateItem(props.item.id, payload);
    notes.value = updatedNotes;

    // Emitir evento de atualização
    emit('item-updated');
  } catch (error) {
    console.error('Erro ao remover a nota:', error);
  } finally {
    savingNotes.value = false;
  }
};

// Função para iniciar edição de uma nota
const startEditNote = note => {
  editingNoteId.value = note.id;
  editingNoteContent.value = note.content;
};

// Função para salvar nota editada
const saveEditedNote = async () => {
  if (!editingNoteContent.value.trim()) return;

  try {
    savingNotes.value = true;
    const updatedNotes = notes.value.map(note => {
      if (note.id === editingNoteId.value) {
        return {
          ...note,
          content: editingNoteContent.value,
          updated_at: new Date().toISOString(),
        };
      }
      return note;
    });

    const payload = {
      funnel_id: props.item.funnel_id,
      funnel_stage: props.item.funnel_stage,
      position: props.item.position,
      item_details: {
        ...props.item.item_details,
        notes: updatedNotes,
      },
    };

    await KanbanAPI.updateItem(props.item.id, payload);
    notes.value = updatedNotes;
    editingNoteId.value = null;
    editingNoteContent.value = '';

    // Emitir evento de atualização
    emit('item-updated');
  } catch (error) {
    console.error('Erro ao editar a nota:', error);
  } finally {
    savingNotes.value = false;
  }
};

// Função para cancelar edição
const cancelEdit = () => {
  editingNoteId.value = null;
  editingNoteContent.value = '';
};

// Função para buscar detalhes de um item vinculado
const getLinkedItemDetails = itemId => {
  return kanbanItems.value.find(item => item.id === itemId);
};

// Função para selecionar um item
const selectItem = item => {
  selectedItemId.value = item.id;
  showItemSelector.value = false;
};

// Função para buscar dados da conversa para uma nota
const fetchNoteConversationData = async conversationId => {
  if (!conversationId) return null;

  try {
    const response = await conversationAPI.get({});
    const conversations = response.data.data.payload;
    return conversations.find(c => c.id === conversationId);
  } catch (error) {
    console.error('Erro ao carregar conversa:', error);
    return null;
  }
};

// Função para buscar dados do contato para uma nota
const fetchNoteContactData = async contactId => {
  if (!contactId) return null;

  try {
    const response = await contacts.get();
    const contactsList = response.data?.payload;
    return contactsList.find(c => c.id === contactId);
  } catch (error) {
    console.error('Erro ao carregar contato:', error);
    return null;
  }
};

// Métodos para gerenciar o checklist
const addChecklistItem = async () => {
  if (!newChecklistItem.value.trim()) return;

  try {
    // Cria o novo item
    const newItem = {
      id: Date.now(),
      text: newChecklistItem.value.trim(),
      completed: false,
      created_at: new Date().toISOString(),
      linked_item_id: selectedItemId.value,
      linked_conversation_id: selectedConversationId.value,
      linked_contact_id: selectedContactId.value,
    };

    // Cria uma nova lista com o item adicionado
    const updatedChecklist = [...checklistItems.value, newItem];

    const payload = {
      account_id: props.item.account_id,
      funnel_id: props.item.funnel_id,
      funnel_stage: props.item.funnel_stage,
      position: props.item.position,
      custom_attributes: props.item.custom_attributes || {},
      item_details: {
        ...props.item.item_details,
        checklist: updatedChecklist,
      },
      timer_started_at: props.item.timer_started_at,
      timer_duration: props.item.timer_duration,
    };

    const { data } = await KanbanAPI.updateItem(props.item.id, payload);

    // Atualiza o estado local diretamente com a resposta da API
    checklistItems.value = data.item_details.checklist;
    newChecklistItem.value = '';

    // Limpa as seleções após adicionar
    selectedItemId.value = null;
    selectedConversationId.value = null;
    selectedContactId.value = null;

    // Ainda emite o evento para manter o componente pai atualizado
    emit('update:item', data);
    emit('item-updated');
  } catch (error) {
    console.error('Erro ao adicionar item ao checklist:', error);
  }
};

const toggleChecklistItem = async item => {
  try {
    const updatedChecklist = checklistItems.value.map(i => ({
      ...i,
      completed: i.id === item.id ? !i.completed : i.completed,
    }));

    const payload = {
      account_id: props.item.account_id,
      funnel_id: props.item.funnel_id,
      funnel_stage: props.item.funnel_stage,
      position: props.item.position,
      custom_attributes: props.item.custom_attributes || {},
      item_details: {
        ...props.item.item_details,
        checklist: updatedChecklist,
      },
      timer_started_at: props.item.timer_started_at,
      timer_duration: props.item.timer_duration,
    };

    const { data } = await KanbanAPI.updateItem(props.item.id, payload);

    // Atualiza o estado local diretamente com a resposta da API
    checklistItems.value = data.item_details.checklist;

    // Ainda emite o evento para manter o componente pai atualizado
    emit('update:item', data);

    // Emitir evento de atualização
    emit('item-updated');
  } catch (error) {
    console.error('Erro ao atualizar item do checklist:', error);
  }
};

const deleteChecklist = async () => {
  try {
    const payload = {
      account_id: props.item.account_id,
      funnel_id: props.item.funnel_id,
      funnel_stage: props.item.funnel_stage,
      position: props.item.position,
      custom_attributes: props.item.custom_attributes || {},
      item_details: {
        ...props.item.item_details,
        checklist: [],
      },
      timer_started_at: props.item.timer_started_at,
      timer_duration: props.item.timer_duration,
    };

    const { data } = await KanbanAPI.updateItem(props.item.id, payload);

    // Atualiza o estado local diretamente com a resposta da API
    checklistItems.value = data.item_details.checklist || [];

    // Ainda emite o evento para manter o componente pai atualizado
    emit('update:item', data);

    // Emitir evento de atualização
    emit('item-updated');
  } catch (error) {
    console.error('Erro ao deletar checklist:', error);
  }
};

const removeChecklistItem = async itemToRemove => {
  try {
    // Remove o item do ref local
    const updatedChecklist = checklistItems.value.filter(
      item => item.id !== itemToRemove.id
    );

    // Atualiza o ref local
    checklistItems.value = updatedChecklist;

    // Prepara o payload com o item atualizado
    const payload = {
      ...props.item,
      item_details: {
        ...props.item.item_details,
        checklist: updatedChecklist,
      },
    };

    // Chama a API para atualizar
    const { data } = await KanbanAPI.updateItem(props.item.id, payload);

    // Emite o evento para atualizar o componente pai
    emit('update:item', data);

    // Emitir evento de atualização
    emit('item-updated');
  } catch (error) {
    // Em caso de erro, reverte a alteração local
    checklistItems.value = props.item.item_details?.checklist || [];
    console.error('Erro ao remover item do checklist:', error);
  }
};

const toggleItemSelector = () => {
  showItemSelector.value = !showItemSelector.value;
  if (showItemSelector.value) {
    showConversationSelector.value = false;
    showContactSelector.value = false;
  }
};

const toggleConversationSelector = () => {
  showConversationSelector.value = !showConversationSelector.value;
  if (showConversationSelector.value) {
    showItemSelector.value = false;
    showContactSelector.value = false;
  }
};

const toggleContactSelector = () => {
  showContactSelector.value = !showContactSelector.value;
  if (showContactSelector.value) {
    showItemSelector.value = false;
    showConversationSelector.value = false;
  }
};

const itemButtonText = computed(() => {
  if (showItemSelector.value) {
    return t('CANCEL');
  }

  if (selectedItemId.value) {
    const selectedItem = kanbanItems.value.find(
      item => item.id === selectedItemId.value
    );
    return selectedItem?.title || t('KANBAN.FORM.NOTES.LINK_ITEM');
  }

  return t('KANBAN.FORM.NOTES.LINK_ITEM');
});

const conversationButtonText = computed(() => {
  if (showConversationSelector.value) {
    return t('CANCEL');
  }

  if (selectedConversationId.value) {
    const selectedConversation = conversations.value.find(
      conv => conv.id === selectedConversationId.value
    );
    return selectedConversation
      ? `#${selectedConversation.id} - ${selectedConversation.title}`
      : t('KANBAN.FORM.NOTES.LINK_CONVERSATION');
  }

  return t('KANBAN.FORM.NOTES.LINK_CONVERSATION');
});

const contactButtonText = computed(() => {
  if (showContactSelector.value) {
    return t('CANCEL');
  }

  if (selectedContactId.value) {
    const selectedContact = contactsList.value.find(
      contact => contact.id === selectedContactId.value
    );
    return selectedContact?.name || t('KANBAN.FORM.NOTES.LINK_CONTACT');
  }

  return t('KANBAN.FORM.NOTES.LINK_CONTACT');
});

const checklistItemButtonText = computed(() => {
  if (showItemSelector.value) {
    return t('CANCEL');
  }

  if (selectedItemId.value) {
    const selectedItem = kanbanItems.value.find(
      item => item.id === selectedItemId.value
    );
    return selectedItem?.title || t('KANBAN.FORM.NOTES.LINK_ITEM');
  }

  return t('KANBAN.FORM.NOTES.LINK_ITEM');
});

onMounted(() => {
  checklistItems.value = props.item.item_details?.checklist || [];
  fetchAgentData();
  fetchConversationData();
  fetchKanbanItems();
  fetchConversations();
  fetchContacts();
});
</script>

<template>
  <div class="p-6 space-y-6" style="min-width: 640px">
    <!-- Cabeçalho com título e prioridade na mesma linha -->
    <div>
      <div class="flex items-center gap-3 mb-2">
        <h3 class="text-lg font-medium text-slate-900 dark:text-slate-100">
          {{ item.title }}
        </h3>
        <div class="flex items-center gap-2">
          <PriorityIcon :priority="item.priority" />
          <span
            class="px-2 py-1 text-xs font-medium rounded-full"
            :class="priorityClass"
          >
            {{ t(`PRIORITY_LABELS.${item.priority.toUpperCase()}`) }}
          </span>
        </div>
      </div>
      <p
        v-if="item.description"
        class="text-sm text-slate-600 dark:text-slate-400"
      >
        {{ item.description }}
      </p>
    </div>

    <div class="space-y-4">
      <!-- Agente Responsável -->
      <div class="flex items-center justify-between">
        <span class="text-sm font-medium text-slate-700 dark:text-slate-300">
          {{ t('KANBAN.FORM.AGENT.LABEL') }}
        </span>
        <div class="flex items-center gap-2">
          <div v-if="loadingAgent" class="text-sm text-slate-400">
            {{ t('KANBAN.LOADING_AGENT') }}
          </div>
          <div v-else-if="agentInfo" class="flex items-center gap-2">
            <Avatar
              :name="agentInfo.name"
              :src="agentInfo.avatar_url"
              :size="24"
            />
            <span class="text-sm text-slate-900 dark:text-slate-100">
              {{ agentInfo.name }}
            </span>
          </div>
          <div v-else class="text-sm text-slate-400 dark:text-slate-600">
            {{ t('KANBAN.NO_AGENT_ASSIGNED') }}
          </div>
        </div>
      </div>

      <!-- Conversa Relacionada -->
      <div
        v-if="props.item.item_details?.conversation_id"
        class="flex items-center justify-between"
      >
        <span class="text-sm font-medium text-slate-700 dark:text-slate-300">
          {{ t('KANBAN.FORM.CONVERSATION.LABEL') }}
        </span>
        <div
          class="flex items-center gap-2 p-2 bg-slate-50 dark:bg-slate-800 rounded-lg border border-slate-100 dark:border-slate-700 min-h-[1.5rem]"
        >
          <div class="flex items-center gap-2">
            <fluent-icon
              icon="chat"
              class="text-slate-500 dark:text-slate-400 flex-shrink-0"
              size="16"
            />
            <div
              v-if="conversationData"
              class="flex items-center justify-between gap-2"
            >
              <p
                class="text-xs text-slate-700 dark:text-slate-300 truncate my-0"
              >
                #{{ conversationData.id }} -
                {{
                  conversationData.meta.sender.name ||
                  conversationData.meta.sender.email ||
                  t('KANBAN.CONVERSATION_NO_CONTACT')
                }}
              </p>
              <span
                v-if="conversationData.unread_count > 0"
                class="flex items-center justify-center h-4 min-w-[1rem] px-1 text-[0.625rem] font-medium bg-ruby-500 text-white rounded-full flex-shrink-0"
              >
                {{
                  conversationData.unread_count > 9
                    ? '9+'
                    : conversationData.unread_count
                }}
              </span>
            </div>
            <div
              v-else-if="loadingConversation"
              class="text-xs text-slate-500 flex items-center"
            >
              {{ t('KANBAN.LOADING_CONVERSATION') }}
            </div>
            <div v-else class="text-xs text-slate-500 flex items-center">
              {{ t('KANBAN.CONVERSATION_NOT_FOUND') }}
            </div>
          </div>
        </div>
      </div>

      <!-- Valor -->
      <div v-if="formattedValue" class="flex items-center justify-between">
        <span class="text-sm font-medium text-slate-700 dark:text-slate-300">
          {{ t('KANBAN.FORM.VALUE.LABEL') }}
        </span>
        <span class="text-sm text-slate-900 dark:text-slate-100">
          {{ formattedValue }}
        </span>
      </div>

      <!-- Data de Criação -->
      <div class="flex items-center justify-between">
        <span class="text-sm font-medium text-slate-700 dark:text-slate-300">
          Criado em
        </span>
        <span class="text-sm text-slate-900 dark:text-slate-100">
          {{ item.createdAt }}
        </span>
      </div>

      <!-- Campo de Notas -->
      <div class="space-y-2 border-t dark:border-slate-700 pt-4">
        <div class="flex items-center justify-between mb-3">
          <button
            class="flex items-center gap-2 text-sm font-medium text-slate-700 hover:text-slate-900 dark:text-slate-300 dark:hover:text-slate-100"
            @click="isNotesExpanded = !isNotesExpanded"
          >
            <fluent-icon
              :icon="isNotesExpanded ? 'chevron-down' : 'chevron-right'"
              size="16"
              class="text-slate-400"
            />
            {{ t('KANBAN.FORM.NOTES.LABEL') }}
          </button>
          <span class="text-xs text-slate-500">
            {{ notes.length }} {{ t('KANBAN.FORM.NOTES.COUNT', notes.length) }}
          </span>
        </div>

        <transition
          enter-active-class="transition-all duration-200 ease-out"
          enter-from-class="opacity-0 -translate-y-2"
          enter-to-class="opacity-100 translate-y-0"
          leave-active-class="transition-all duration-200 ease-in"
          leave-from-class="opacity-100 translate-y-0"
          leave-to-class="opacity-0 -translate-y-2"
        >
          <div v-show="isNotesExpanded">
            <!-- Input para nova nota -->
            <div class="mt-3">
              <div class="relative">
                <div class="absolute top-2 right-2">
                  <fluent-icon
                    icon="comment-add"
                    class="text-slate-400 dark:text-slate-500"
                    size="16"
                  />
                </div>
                <textarea
                  v-model="currentNote"
                  rows="3"
                  class="w-full px-4 py-3 border border-slate-200 bg-slate-50 rounded-lg focus:ring-1 focus:ring-woot-500 focus:border-woot-500 dark:bg-slate-800 dark:border-slate-700 dark:text-slate-300 placeholder-slate-400 dark:placeholder-slate-500 resize-none"
                  :placeholder="t('KANBAN.FORM.NOTES.PLACEHOLDER')"
                />
              </div>

              <!-- Seletor de item vinculado -->
              <div class="mt-2 flex items-center justify-between">
                <div class="flex items-center gap-2">
                  <button
                    class="flex items-center gap-1 text-sm text-slate-600 hover:text-woot-500 dark:text-slate-400 dark:hover:text-woot-400"
                    @click="toggleItemSelector"
                  >
                    <fluent-icon
                      :icon="showItemSelector ? 'dismiss-circle' : 'link'"
                      size="14"
                    />
                    <span class="flex items-center gap-1">
                      {{ itemButtonText }}
                      <fluent-icon
                        v-if="selectedItemId && !showItemSelector"
                        icon="dismiss-circle"
                        size="14"
                        class="cursor-pointer hover:text-ruby-500"
                        @click.stop="selectedItemId = null"
                      />
                    </span>
                  </button>

                  <button
                    class="flex items-center gap-1 text-sm text-slate-600 hover:text-woot-500 dark:text-slate-400 dark:hover:text-woot-400"
                    @click="toggleConversationSelector"
                  >
                    <fluent-icon
                      :icon="
                        showConversationSelector ? 'dismiss-circle' : 'chat'
                      "
                      size="14"
                    />
                    <span class="flex items-center gap-1">
                      {{ conversationButtonText }}
                      <fluent-icon
                        v-if="
                          selectedConversationId && !showConversationSelector
                        "
                        icon="dismiss-circle"
                        size="14"
                        class="cursor-pointer hover:text-ruby-500"
                        @click.stop="selectedConversationId = null"
                      />
                    </span>
                  </button>

                  <button
                    class="flex items-center gap-1 text-sm text-slate-600 hover:text-woot-500 dark:text-slate-400 dark:hover:text-woot-400"
                    @click="toggleContactSelector"
                  >
                    <fluent-icon
                      :icon="showContactSelector ? 'dismiss-circle' : 'person'"
                      size="14"
                    />
                    <span class="flex items-center gap-1">
                      {{ contactButtonText }}
                      <fluent-icon
                        v-if="selectedContactId && !showContactSelector"
                        icon="dismiss-circle"
                        size="14"
                        class="cursor-pointer hover:text-ruby-500"
                        @click.stop="selectedContactId = null"
                      />
                    </span>
                  </button>
                </div>

                <woot-button
                  variant="solid"
                  color-scheme="primary"
                  size="small"
                  :loading="savingNotes"
                  :disabled="!currentNote.trim()"
                  @click="addNote"
                >
                  <fluent-icon icon="add" class="mr-1" size="12" />
                  {{ t('KANBAN.FORM.NOTES.ADD') }}
                </woot-button>
              </div>

              <!-- Seletor de itens -->
              <div
                v-if="showItemSelector"
                class="absolute z-50 left-8 right-8 mt-2 bg-white dark:bg-slate-800 rounded-lg border border-slate-200 dark:border-slate-700 shadow-lg"
              >
                <div
                  class="px-4 py-3 border-b border-slate-200 dark:border-slate-700"
                >
                  <h4
                    class="text-sm font-medium text-slate-700 dark:text-slate-300"
                  >
                    {{ t('KANBAN.FORM.NOTES.SELECT_ITEM') }}
                  </h4>
                </div>

                <div class="max-h-[280px] overflow-y-auto custom-scrollbar">
                  <div
                    v-if="loadingItems"
                    class="p-4 text-center text-sm text-slate-500"
                  >
                    <span class="loading-spinner w-4 h-4 mr-2" />
                    {{ t('KANBAN.LOADING') }}
                  </div>

                  <div
                    v-else-if="kanbanItems.length === 0"
                    class="p-4 text-center"
                  >
                    <p class="text-sm text-slate-500">
                      {{ t('KANBAN.FORM.NOTES.NO_ITEMS') }}
                    </p>
                  </div>

                  <div
                    v-else
                    class="divide-y divide-slate-100 dark:divide-slate-700"
                  >
                    <button
                      v-for="item in kanbanItems"
                      :key="item.id"
                      class="w-full px-4 py-3 text-left hover:bg-slate-50 dark:hover:bg-slate-700 transition-colors focus:outline-none focus:bg-slate-50 dark:focus:bg-slate-700"
                      @click="selectItem(item)"
                    >
                      <div class="flex items-center gap-3">
                        <!-- Prioridade -->
                        <div
                          class="flex-shrink-0 w-1.5 h-5 rounded-full"
                          :class="{
                            'bg-ruby-500': item.priority === 'high',
                            'bg-yellow-500': item.priority === 'medium',
                            'bg-green-500': item.priority === 'low',
                            'bg-slate-300': item.priority === 'none',
                          }"
                        />

                        <div class="flex items-center gap-3 flex-1 min-w-0">
                          <!-- Título -->
                          <h4
                            class="text-sm font-medium text-slate-900 dark:text-slate-100 truncate flex-1"
                          >
                            {{ item.title }}
                          </h4>

                          <!-- Etapa -->
                          <span
                            class="px-2 py-0.5 text-xs font-medium rounded-full bg-slate-100 dark:bg-slate-700 text-slate-600 dark:text-slate-300 whitespace-nowrap"
                          >
                            {{ item.stage_name }}
                          </span>

                          <!-- Data -->
                          <span
                            class="flex items-center gap-1 text-xs text-slate-400 whitespace-nowrap"
                          >
                            <fluent-icon icon="calendar" size="12" />
                            {{ item.createdAt }}
                          </span>
                        </div>
                      </div>
                      <!-- Descrição -->
                      <p
                        v-if="item.description"
                        class="mt-1 ml-6 text-xs text-slate-500 dark:text-slate-400 line-clamp-1"
                      >
                        {{ item.description }}
                      </p>
                    </button>
                  </div>
                </div>
              </div>

              <!-- Seletor de conversas -->
              <div
                v-if="showConversationSelector"
                class="absolute z-50 left-8 right-8 mt-2 bg-white dark:bg-slate-800 rounded-lg border border-slate-200 dark:border-slate-700 shadow-lg"
              >
                <div
                  class="px-4 py-3 border-b border-slate-200 dark:border-slate-700"
                >
                  <h4
                    class="text-sm font-medium text-slate-700 dark:text-slate-300"
                  >
                    {{ t('KANBAN.FORM.NOTES.SELECT_CONVERSATION') }}
                  </h4>
                </div>

                <div class="max-h-[280px] overflow-y-auto custom-scrollbar">
                  <div
                    v-if="loadingConversations"
                    class="p-4 text-center text-sm text-slate-500"
                  >
                    <span class="loading-spinner w-4 h-4 mr-2" />
                    {{ t('KANBAN.LOADING') }}
                  </div>

                  <div
                    v-else-if="conversations.length === 0"
                    class="p-4 text-center"
                  >
                    <p class="text-sm text-slate-500">
                      {{ t('KANBAN.FORM.NOTES.NO_CONVERSATIONS') }}
                    </p>
                  </div>

                  <div
                    v-else
                    class="divide-y divide-slate-100 dark:divide-slate-700"
                  >
                    <button
                      v-for="conversation in conversations"
                      :key="conversation.id"
                      class="w-full px-4 py-3 text-left hover:bg-slate-50 dark:hover:bg-slate-700 transition-colors focus:outline-none focus:bg-slate-50 dark:focus:bg-slate-700"
                      @click="selectConversation(conversation)"
                    >
                      <div class="flex items-center gap-3">
                        <!-- Status -->
                        <div
                          class="flex-shrink-0 w-1.5 h-5 rounded-full"
                          :class="{
                            'bg-green-500': conversation.status === 'open',
                            'bg-yellow-500': conversation.status === 'pending',
                            'bg-slate-500': conversation.status === 'resolved',
                          }"
                        />

                        <div class="flex items-center gap-3 flex-1 min-w-0">
                          <!-- ID e Título -->
                          <h4
                            class="text-sm font-medium text-slate-900 dark:text-slate-100 truncate flex-1"
                          >
                            #{{ conversation.id }} - {{ conversation.title }}
                          </h4>

                          <!-- Canal -->
                          <span
                            class="px-2 py-0.5 text-xs font-medium rounded-full bg-slate-100 dark:bg-slate-700 text-slate-600 dark:text-slate-300 whitespace-nowrap"
                          >
                            {{ conversation.channel_type }}
                          </span>

                          <!-- Data -->
                          <span
                            class="flex items-center gap-1 text-xs text-slate-400 whitespace-nowrap"
                          >
                            <fluent-icon icon="contact-card" size="12" />
                            {{ conversation.created_at }}
                          </span>
                        </div>
                      </div>
                      <!-- Última mensagem -->
                      <p
                        v-if="conversation.description"
                        class="mt-1 ml-6 text-xs text-slate-500 dark:text-slate-400 line-clamp-1"
                      >
                        {{ conversation.description }}
                      </p>
                    </button>
                  </div>
                </div>
              </div>

              <!-- Seletor de contatos -->
              <div
                v-if="showContactSelector"
                class="absolute z-50 left-8 right-8 mt-2 bg-white dark:bg-slate-800 rounded-lg border border-slate-200 dark:border-slate-700 shadow-lg"
              >
                <div
                  class="px-4 py-3 border-b border-slate-200 dark:border-slate-700"
                >
                  <h4
                    class="text-sm font-medium text-slate-700 dark:text-slate-300"
                  >
                    {{ t('KANBAN.FORM.NOTES.SELECT_CONTACT') }}
                  </h4>
                </div>

                <div class="max-h-[280px] overflow-y-auto custom-scrollbar">
                  <div
                    v-if="loadingContacts"
                    class="p-4 text-center text-sm text-slate-500"
                  >
                    <span class="loading-spinner w-4 h-4 mr-2" />
                    {{ t('KANBAN.LOADING') }}
                  </div>

                  <div
                    v-else-if="contactsList.length === 0"
                    class="p-4 text-center"
                  >
                    <p class="text-sm text-slate-500">
                      {{ t('KANBAN.FORM.NOTES.NO_CONTACTS') }}
                    </p>
                  </div>

                  <div
                    v-else
                    class="divide-y divide-slate-100 dark:divide-slate-700"
                  >
                    <button
                      v-for="contact in contactsList"
                      :key="contact.id"
                      class="w-full px-4 py-3 text-left hover:bg-slate-50 dark:hover:bg-slate-700 transition-colors focus:outline-none focus:bg-slate-50 dark:focus:bg-slate-700"
                      @click="selectContact(contact)"
                    >
                      <div class="flex items-center gap-3">
                        <Avatar
                          :src="contact.avatar_url"
                          :name="contact.name"
                          :size="24"
                        />

                        <div class="flex items-center gap-3 flex-1 min-w-0">
                          <!-- Nome -->
                          <h4
                            class="text-sm font-medium text-slate-900 dark:text-slate-100 truncate flex-1"
                          >
                            {{ contact.name }}
                          </h4>

                          <!-- Email -->
                          <span
                            v-if="contact.email"
                            class="text-xs text-slate-500 dark:text-slate-400 truncate max-w-[200px]"
                          >
                            {{ contact.email }}
                          </span>

                          <!-- Data -->
                          <span
                            class="flex items-center gap-1 text-xs text-slate-400 whitespace-nowrap"
                          >
                            <fluent-icon icon="calendar" size="12" />
                            {{ contact.created_at }}
                          </span>
                        </div>
                      </div>
                      <!-- Informações adicionais -->
                      <div
                        v-if="contact.phone || contact.last_activity_at"
                        class="mt-1 ml-9 flex items-center gap-4 text-xs text-slate-500"
                      >
                        <span
                          v-if="contact.phone"
                          class="flex items-center gap-1"
                        >
                          <fluent-icon icon="call" size="12" />
                          {{ contact.phone }}
                        </span>
                        <span
                          v-if="contact.last_activity_at"
                          class="flex items-center gap-1"
                        >
                          <fluent-icon icon="calendar-clock" size="12" />
                          {{ t('KANBAN.FORM.NOTES.LAST_ACTIVITY') }}:
                          {{ contact.last_activity_at }}
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>

              <!-- Lista de notas -->
              <div v-if="notes.length > 0" class="mt-4 space-y-3">
                <div
                  v-for="note in [...notes].reverse()"
                  :key="note.id"
                  class="p-3 bg-white dark:bg-slate-800 rounded-lg border border-slate-200 dark:border-slate-700"
                >
                  <div class="flex items-start justify-between gap-2">
                    <div v-if="editingNoteId === note.id" class="flex-1">
                      <textarea
                        v-model="editingNoteContent"
                        rows="2"
                        class="w-full px-3 py-2 text-sm border border-slate-200 bg-slate-50 rounded-lg focus:ring-1 focus:ring-woot-500 focus:border-woot-500 dark:bg-slate-800 dark:border-slate-700 dark:text-slate-300 resize-none"
                        @keydown.esc="cancelEdit"
                      />
                      <div class="flex justify-end gap-2 mt-2">
                        <woot-button
                          variant="clear"
                          color-scheme="secondary"
                          size="small"
                          @click="cancelEdit"
                        >
                          {{ t('CANCEL') }}
                        </woot-button>
                        <woot-button
                          variant="solid"
                          color-scheme="primary"
                          size="small"
                          :loading="savingNotes"
                          :disabled="!editingNoteContent.trim()"
                          @click="saveEditedNote"
                        >
                          {{ t('SAVE') }}
                        </woot-button>
                      </div>
                    </div>
                    <p
                      v-else
                      class="flex-1 text-sm text-slate-700 dark:text-slate-300 whitespace-pre-wrap"
                    >
                      {{ note.content }}
                    </p>
                    <div v-if="!editingNoteId" class="flex items-center gap-1">
                      <button
                        class="p-1 text-slate-400 hover:text-slate-600 dark:hover:text-slate-300"
                        @click="startEditNote(note)"
                      >
                        <fluent-icon icon="edit" size="12" />
                      </button>
                      <button
                        class="p-1 text-slate-400 hover:text-ruby-600 dark:hover:text-ruby-400"
                        @click="removeNote(note.id)"
                      >
                        <fluent-icon icon="delete" size="12" />
                      </button>
                    </div>
                  </div>

                  <!-- Item vinculado -->
                  <div
                    v-if="note.linked_item_id"
                    class="mt-2 pt-2 border-t border-slate-100 dark:border-slate-700"
                  >
                    <div
                      v-if="getLinkedItemDetails(note.linked_item_id)"
                      class="flex items-center gap-2 text-xs"
                    >
                      <fluent-icon
                        icon="link"
                        class="text-slate-400"
                        size="12"
                      />
                      <span class="text-slate-500 dark:text-slate-400">
                        {{ t('KANBAN.FORM.NOTES.LINKED_TO') }}:
                      </span>
                      <span class="text-slate-700 dark:text-slate-300">
                        {{ getLinkedItemDetails(note.linked_item_id).title }}
                      </span>
                    </div>
                  </div>

                  <!-- Metadados da nota -->
                  <div
                    class="mt-2 flex items-center justify-between text-xs text-slate-500 dark:text-slate-400"
                  >
                    <span>{{ note.agent }}</span>
                    <span>
                      {{ formatDate(note.updated_at || note.created_at) }}
                      {{ note.updated_at ? t('KANBAN.FORM.NOTES.EDITED') : '' }}
                    </span>
                  </div>

                  <!-- Após o item vinculado -->
                  <div
                    v-if="note.linked_conversation_id && note.conversation_data"
                    class="mt-2 pt-2 border-t border-slate-100 dark:border-slate-700"
                  >
                    <div
                      class="flex items-center gap-2 p-2 bg-slate-50 dark:bg-slate-800 rounded-lg border border-slate-100 dark:border-slate-700"
                    >
                      <fluent-icon
                        icon="chat"
                        class="text-slate-500 dark:text-slate-400 flex-shrink-0"
                        size="12"
                      />
                      <div class="flex items-center justify-between w-full">
                        <p
                          class="text-xs text-slate-700 dark:text-slate-300 truncate my-0"
                        >
                          #{{ note.conversation_data.id }} -
                          {{
                            note.conversation_data.meta.sender.name ||
                            note.conversation_data.meta.sender.email ||
                            t('KANBAN.CONVERSATION_NO_CONTACT')
                          }}
                        </p>
                        <span
                          v-if="note.conversation_data.unread_count > 0"
                          class="flex items-center justify-center h-4 min-w-[1rem] px-1 text-[0.625rem] font-medium bg-ruby-500 text-white rounded-full flex-shrink-0"
                        >
                          {{
                            note.conversation_data.unread_count > 9
                              ? '9+'
                              : note.conversation_data.unread_count
                          }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <!-- Contato vinculado -->
                  <div
                    v-if="note.linked_contact_id && note.contact_data"
                    class="mt-2 pt-2 border-t border-slate-100 dark:border-slate-700"
                  >
                    <div
                      class="flex items-center gap-2 p-2 bg-slate-50 dark:bg-slate-800 rounded-lg border border-slate-100 dark:border-slate-700"
                    >
                      <Avatar
                        :src="note.contact_data.avatar_url"
                        :name="note.contact_data.name"
                        :size="20"
                      />
                      <div class="flex items-center gap-2 flex-1">
                        <span
                          class="text-xs text-slate-700 dark:text-slate-300"
                        >
                          {{ note.contact_data.name }}
                        </span>
                        <span
                          v-if="note.contact_data.email"
                          class="text-xs text-slate-500 dark:text-slate-400 truncate max-w-[200px]"
                        >
                          {{ note.contact_data.email }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Mensagem quando não há notas -->
              <div
                v-else
                class="mt-4 p-4 text-center text-sm text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-800 rounded-lg"
              >
                {{ t('KANBAN.FORM.NOTES.EMPTY') }}
              </div>
            </div>
          </div>
        </transition>
      </div>

      <!-- Checklist -->
      <div
        class="p-4 border-2 border-dashed border-woot-500/30 dark:border-woot-500/20 rounded-lg"
      >
        <div class="flex items-center justify-between mb-3">
          <div class="flex items-center gap-2">
            <fluent-icon icon="checkmark" size="16" class="text-slate-400" />
            <span
              class="text-sm font-medium text-slate-700 dark:text-slate-300"
            >
              {{ t('KANBAN.FORM.CHECKLIST.LABEL') }}
            </span>
          </div>
          <div class="flex items-center gap-2">
            <span class="text-xs text-slate-500">
              {{ completedItems }}/{{ totalItems }}
              {{ t('KANBAN.FORM.CHECKLIST.ITEMS') }}
            </span>
            <button
              class="text-sm text-slate-400 hover:text-slate-600 dark:hover:text-slate-300"
              @click="hideCompletedItems = !hideCompletedItems"
            >
              {{ t('KANBAN.FORM.CHECKLIST.HIDE_COMPLETED') }}
            </button>
            <button
              class="text-sm text-ruby-600 hover:text-ruby-700 dark:text-ruby-400 dark:hover:text-ruby-300"
              @click="deleteChecklist"
            >
              {{ t('DELETE') }}
            </button>
          </div>
        </div>

        <!-- Barra de Progresso -->
        <div
          class="h-1.5 bg-slate-100 dark:bg-slate-700 rounded-full overflow-hidden"
        >
          <div
            class="h-full bg-woot-500 transition-all duration-300 ease-out"
            :style="{ width: `${checklistProgress}%` }"
          />
        </div>

        <!-- Lista de Items -->
        <div v-if="checklistItems.length > 0" class="space-y-2">
          <div
            v-for="item in filteredChecklistItems"
            :key="item.id"
            class="group flex items-center gap-3 p-2 hover:bg-slate-50/50 dark:hover:bg-slate-800/50 rounded-lg transition-colors duration-200"
          >
            <!-- Checkbox customizado -->
            <label class="relative flex items-center cursor-pointer">
              <input
                type="checkbox"
                :checked="item.completed"
                class="peer h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-slate-300 dark:border-slate-600 checked:border-woot-500 dark:checked:border-woot-500 checked:bg-woot-500 dark:checked:bg-woot-500 transition-all duration-200"
                @change="toggleChecklistItem(item)"
              />
              <fluent-icon
                icon="checkmark"
                size="12"
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white opacity-0 peer-checked:opacity-100 transition-opacity duration-200"
              />
            </label>

            <!-- Texto do item e itens vinculados -->
            <div class="flex items-center gap-2 flex-1">
              <span
                :class="{
                  'line-through text-slate-400 dark:text-slate-500':
                    item.completed,
                  'text-slate-700 dark:text-slate-300': !item.completed,
                }"
                class="text-sm transition-colors duration-200"
              >
                {{ item.text }}
              </span>

              <!-- Item vinculado -->
              <div
                v-if="item.linked_item_id"
                class="flex items-center gap-1 px-2 py-0.5 text-xs bg-slate-100 dark:bg-slate-700 text-slate-600 dark:text-slate-300 rounded-full"
              >
                <fluent-icon icon="link" size="10" />
                <span class="truncate max-w-[150px]">
                  {{
                    getLinkedItemDetails(item.linked_item_id)?.title ||
                    t('KANBAN.FORM.NOTES.ITEM_NOT_FOUND')
                  }}
                </span>
              </div>

              <!-- Conversa vinculada -->
              <div
                v-if="item.linked_conversation_id"
                class="flex items-center gap-1 px-2 py-0.5 text-xs bg-slate-100 dark:bg-slate-700 text-slate-600 dark:text-slate-300 rounded-full"
              >
                <fluent-icon icon="chat" size="10" />
                <span class="truncate max-w-[150px]">
                  #{{ item.linked_conversation_id }}
                </span>
              </div>

              <!-- Contato vinculado -->
              <div
                v-if="item.linked_contact_id"
                class="flex items-center gap-1 px-2 py-0.5 text-xs bg-slate-100 dark:bg-slate-700 text-slate-600 dark:text-slate-300 rounded-full"
              >
                <fluent-icon icon="person" size="10" />
                <span class="truncate max-w-[150px]">
                  {{
                    contactsList.value.find(
                      c => c.id === item.linked_contact_id
                    )?.name || t('KANBAN.FORM.NOTES.CONTACT_NOT_FOUND')
                  }}
                </span>
              </div>
            </div>

            <!-- Botão de remover -->
            <button
              class="opacity-0 group-hover:opacity-100 p-1 text-slate-400 hover:text-ruby-500 dark:text-slate-500 dark:hover:text-ruby-400 transition-all duration-200"
              @click="removeChecklistItem(item)"
            >
              <fluent-icon icon="dismiss" size="12" />
            </button>
          </div>
        </div>

        <!-- Input para novo item -->
        <div class="mt-3">
          <div class="flex gap-2 mb-2">
            <input
              v-model="newChecklistItem"
              type="text"
              class="flex-1 h-10 px-3 text-sm border border-slate-200 rounded-lg focus:ring-1 focus:ring-woot-500 focus:border-woot-500 dark:bg-slate-800 dark:border-slate-700 dark:text-slate-300"
              :placeholder="t('KANBAN.FORM.CHECKLIST.ADD_ITEM')"
              @keyup.enter="addChecklistItem"
            />
            <button
              class="relative inline-flex items-center h-10 px-4 text-sm font-medium text-white bg-woot-500 hover:bg-woot-600 dark:bg-woot-600 dark:hover:bg-woot-700 rounded-lg transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
              :disabled="!newChecklistItem.trim()"
              @click="addChecklistItem"
            >
              <fluent-icon icon="add" class="mr-1.5" size="14" />
              {{ t('ADD') }}
            </button>
          </div>

          <!-- Botões de vinculação -->
          <div class="flex items-center gap-2 mb-3">
            <div class="relative inline-flex items-center gap-2">
              <button
                disabled
                class="flex items-center gap-1 text-sm text-slate-400 dark:text-slate-600 cursor-not-allowed"
              >
                <fluent-icon icon="link" size="14" />
                <span>{{ t('KANBAN.FORM.NOTES.LINK_ITEM') }}</span>
              </button>

              <button
                disabled
                class="flex items-center gap-1 text-sm text-slate-400 dark:text-slate-600 cursor-not-allowed"
              >
                <fluent-icon icon="chat" size="14" />
                <span>{{ t('KANBAN.FORM.NOTES.LINK_CONVERSATION') }}</span>
              </button>

              <button
                disabled
                class="flex items-center gap-1 text-sm text-slate-400 dark:text-slate-600 cursor-not-allowed"
              >
                <fluent-icon icon="person" size="14" />
                <span>{{ t('KANBAN.FORM.NOTES.LINK_CONTACT') }}</span>
              </button>

              <span
                class="-right-14 px-1.5 py-0.5 text-[10px] font-medium bg-woot-500 text-white rounded whitespace-nowrap"
              >
                {{ t('COMING_SOON') }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- Botões de Ação -->
      <div
        class="flex justify-end space-x-2 pt-4 border-t dark:border-slate-700"
      >
        <woot-button
          variant="clear"
          color-scheme="secondary"
          @click="$emit('close')"
        >
          {{ t('CLOSE') }}
        </woot-button>
        <woot-button
          variant="solid"
          color-scheme="primary"
          @click="$emit('edit', item)"
        >
          {{ t('EDIT') }}
        </woot-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #94a3b8 transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #94a3b8;
  border-radius: 3px;
}

.dark .custom-scrollbar {
  scrollbar-color: #475569 transparent;
}

.dark .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #475569;
}
</style>
