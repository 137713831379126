<script setup>
import { computed, ref, onMounted, nextTick, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import KanbanItem from './KanbanItem.vue';
import CustomContextMenu from '../../../../components/ui/CustomContextMenu.vue';
import KanbanAPI from '../../../../api/kanban';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: 'orange',
  },
  items: {
    type: Array,
    default: () => [],
  },
  count: {
    type: Number,
    default: 0,
  },
  description: {
    type: String,
    default: '',
  },
  totalColumns: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['add', 'edit', 'delete', 'drop', 'item-click']);

const showContextMenu = ref(false);
const contextMenuX = ref(0);
const contextMenuY = ref(0);
const showColumnTotal = ref(true);
const columnItems = ref([]);

const { t } = useI18n();

const darkenColor = color => {
  // Remove o # se existir e converte para RGB
  const hex = color.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Escurece cada componente em 15%
  const darkenAmount = 0.85;
  const dr = Math.floor(r * darkenAmount);
  const dg = Math.floor(g * darkenAmount);
  const db = Math.floor(b * darkenAmount);

  // Converte de volta para hex
  return `#${dr.toString(16).padStart(2, '0')}${dg.toString(16).padStart(2, '0')}${db.toString(16).padStart(2, '0')}`;
};

const textColor = computed(() =>
  props.color ? darkenColor(props.color) : '#CC6E00'
);

const columnStyle = computed(() => ({
  backgroundColor: props.color ? `${props.color}40` : '#f8fafc',
}));

const countStyle = computed(() => ({
  backgroundColor: props.color ? `${props.color}15` : '#FFF4E5',
  color: textColor.value,
}));

const handleDrop = event => {
  const itemId = event.dataTransfer.getData('text/plain');
  emit('drop', { itemId, columnId: props.id });
};

const handleDragOver = event => {
  event.preventDefault();
};

const columnWidth = computed(() => {
  if (props.totalColumns <= 5) {
    // Se tiver 5 ou menos colunas, distribui o espaço igualmente
    return {
      minWidth: '320px',
      maxWidth: `calc((100% - ${(props.totalColumns - 1) * 1}rem) / ${props.totalColumns})`,
      width: '100%',
    };
  }
  // Se tiver mais de 5 colunas, usa largura fixa
  return {
    minWidth: '350px',
    maxWidth: '350px',
    width: '350px',
  };
});

const toggleColumnTotal = async () => {
  showColumnTotal.value = !showColumnTotal.value;
  if (showColumnTotal.value) {
    await fetchColumnItems();
  }
};

const fetchColumnItems = async () => {
  try {
    const response = await KanbanAPI.getItems();
    columnItems.value = response.data.filter(
      item => item.funnel_stage === props.id
    );
  } catch (error) {
    console.error('Erro ao buscar itens:', error);
  }
};

const columnTotal = computed(() => {
  if (!showColumnTotal.value || !columnItems.value.length) return null;

  return columnItems.value.reduce((total, item) => {
    const value = parseFloat(item.item_details?.value) || 0;
    return total + value;
  }, 0);
});

const formattedTotal = computed(() => {
  if (columnTotal.value === null) return '';
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(columnTotal.value);
});

onMounted(async () => {
  await fetchColumnItems();
});
</script>

<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden"
    :style="{ ...columnWidth, ...columnStyle }"
  >
    <!-- Header -->
    <div
      class="pl-4 pr-4 pt-2 pb-2"
      :style="{ borderBottom: `1px solid ${props.color}59` }"
    >
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-2">
          <h3 class="text-sm font-medium" :style="{ color: textColor }">
            {{ title }}
          </h3>
          <span
            class="inline-flex items-center justify-center w-5 h-5 text-xs font-medium rounded-full"
            :style="countStyle"
          >
            {{ count }}
          </span>
        </div>
        <div class="flex items-center gap-2">
          <span
            v-if="formattedTotal"
            class="text-xs font-medium"
            :style="{ color: textColor }"
          >
            {{ formattedTotal }}
          </span>
          <button
            class="p-1 text-slate-600 dark:text-slate-400 hover:text-slate-900 dark:hover:text-slate-100"
            @click="$emit('add')"
          >
            <fluent-icon icon="add" size="16" />
          </button>
        </div>
      </div>
    </div>

    <!-- Container com scroll -->
    <div
      class="flex-1 overflow-y-auto"
      @drop="handleDrop"
      @dragover="handleDragOver"
    >
      <!-- Lista de items -->
      <div class="px-4">
        <div class="py-3 space-y-3">
          <template v-if="items.length">
            <KanbanItem
              v-for="item in items"
              :key="item.id"
              :item="item"
              :draggable="true"
              @dragstart="
                e => {
                  e.dataTransfer.setData('text/plain', item.id.toString());
                }
              "
              @click="$emit('item-click', item)"
              @edit="$emit('edit', item)"
              @delete="$emit('delete', item)"
            />
          </template>
          <div
            v-else
            class="flex items-center justify-center h-24 text-sm text-slate-500 dark:text-slate-400"
          >
            {{ t('KANBAN.NO_ITEMS') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.overflow-y-auto {
  min-height: 0;
  height: 100%;

  // Oculta a scrollbar no Firefox
  scrollbar-width: none;

  // Oculta a scrollbar no Chrome, Safari e outros navegadores
  &::-webkit-scrollbar {
    display: none;
  }
}

/* Adicionando espaçamento entre os itens */
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  margin-top: 0.75rem;
}

// Ajusta o último item para não ter margem inferior
.space-y-3 > :last-child {
  margin-bottom: 0;
}
</style>
