<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import FunnelAPI from '../../../../api/funnel';
import StageColorPicker from './StageColorPicker.vue';
import draggable from 'vuedraggable';

const props = defineProps({
  isEditing: {
    type: Boolean,
    default: false,
  },
  initialData: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['saved', 'close']);
const { t } = useI18n();
const loading = ref(false);

const formData = ref({
  name: props.initialData.name || '',
  description: props.initialData.description || '',
  stages: {},
});

const stages = ref([]);
const newStage = ref({
  name: '',
  color: '#FF6B6B',
  description: '',
});

const editingStage = ref(null);

const dragOptions = {
  animation: 150,
  ghostClass: 'ghost-card',
};

const vFocus = {
  mounted: el => el.focus(),
};

// Gera o ID automaticamente baseado no nome
const generateId = name => {
  return name
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9]+/g, '_') // Substitui caracteres especiais por _
    .replace(/^_+|_+$/g, ''); // Remove _ do início e fim
};

// Atualiza o ID quando o nome muda
watch(
  () => newStage.value.name,
  newName => {
    newStage.value.id = generateId(newName);
  }
);

// Converte as etapas para o formato esperado pela API
const formatStagesForAPI = () => {
  const formattedStages = {};
  stages.value.forEach((stage, index) => {
    formattedStages[stage.id] = {
      name: stage.name,
      color: stage.color,
      position: index + 1,
      description: stage.description,
    };
  });
  return formattedStages;
};

const handleAddStage = e => {
  // Previne a propagação do evento se vier de um clique no ColorPicker
  if (e?.target?.closest('.colorpicker--chrome')) {
    return;
  }

  if (!newStage.value.name) return;

  if (editingStage.value) {
    // Se estiver editando, atualiza a etapa existente
    const index = stages.value.findIndex(s => s.id === editingStage.value.id);
    if (index !== -1) {
      stages.value[index] = {
        ...stages.value[index],
        name: newStage.value.name,
        color: newStage.value.color,
        description: newStage.value.description,
      };

      // Se estiver editando o funil, atualiza imediatamente
      if (props.isEditing) {
        handleOrderChange();
      }
    }
    editingStage.value = null;
  } else {
    // Se não estiver editando, adiciona nova etapa
    stages.value.push({
      ...newStage.value,
      id: generateId(newStage.value.name),
    });
  }

  // Limpa o formulário de nova etapa
  newStage.value = {
    name: '',
    color: '#FF6B6B',
    description: '',
  };
};

const removeStage = index => {
  stages.value.splice(index, 1);
};

// Inicializa as etapas se estiver editando
onMounted(() => {
  if (props.isEditing && props.initialData.stages) {
    stages.value = Object.entries(props.initialData.stages).map(
      ([id, stage]) => ({
        id,
        name: stage.name,
        color: stage.color,
        description: stage.description,
      })
    );
  }
});

const handleSubmit = async () => {
  try {
    loading.value = true;
    const payload = {
      name: formData.value.name,
      description: formData.value.description,
      stages: formatStagesForAPI(),
    };

    let response;

    if (props.isEditing) {
      response = await FunnelAPI.update(props.initialData.id, payload);
    } else {
      response = await FunnelAPI.create(payload);
    }

    if (response.data) {
      emit('saved', response.data);
    }
  } catch (error) {
    console.error('Erro ao salvar funil:', error);
    // Você pode adicionar um toast de erro aqui se desejar
  } finally {
    loading.value = false;
  }
};

const handleOrderChange = async () => {
  if (!props.isEditing) return;

  try {
    const payload = {
      name: formData.value.name,
      description: formData.value.description,
      stages: formatStagesForAPI(),
    };

    await FunnelAPI.update(props.initialData.id, payload);
  } catch (error) {
    console.error('Erro ao atualizar ordem:', error);
  }
};

const startEditing = stage => {
  // Preenche o formulário de nova etapa com os dados da etapa selecionada
  newStage.value = {
    id: stage.id,
    name: stage.name,
    color: stage.color,
    description: stage.description,
  };
  editingStage.value = stage;
};

const saveStageEdit = index => {
  if (!editingStage.value) return;

  stages.value[index] = {
    ...stages.value[index],
    name: editingStage.value.name,
    description: editingStage.value.description,
    color: editingStage.value.color,
  };

  editingStage.value = null;

  // Se estiver editando, atualiza imediatamente
  if (props.isEditing) {
    handleOrderChange();
  }
};

const cancelEditing = () => {
  editingStage.value = null;
  // Limpa o formulário de nova etapa
  newStage.value = {
    name: '',
    color: '#FF6B6B',
    description: '',
  };
};
</script>

<template>
  <form class="funnel-form" @submit.prevent="handleSubmit">
    <div class="grid grid-cols-[1fr_400px] gap-6">
      <!-- Coluna Esquerda - Formulário -->
      <div class="space-y-6">
        <!-- Nome do Funil -->
        <div>
          <label class="block text-sm font-medium mb-2">
            {{ t('KANBAN.FUNNELS.FORM.NAME.LABEL') }}
          </label>
          <input
            v-model="formData.name"
            type="text"
            class="w-full px-3 py-2 border rounded-lg"
            :placeholder="t('KANBAN.FUNNELS.FORM.NAME.PLACEHOLDER')"
            required
          />
        </div>

        <!-- Descrição -->
        <div>
          <label class="block text-sm font-medium mb-2">
            {{ t('KANBAN.FUNNELS.FORM.DESCRIPTION.LABEL') }}
          </label>
          <textarea
            v-model="formData.description"
            rows="3"
            class="w-full px-3 py-2 border rounded-lg"
            :placeholder="t('KANBAN.FUNNELS.FORM.DESCRIPTION.PLACEHOLDER')"
          />
        </div>

        <!-- Formulário Nova Etapa -->
        <div
          class="border rounded-lg p-4 space-y-4"
          :class="{
            'border-2': editingStage,
            'border-slate-200': !editingStage,
          }"
          :style="editingStage ? { borderColor: newStage.color } : {}"
        >
          <h4 class="text-lg font-medium mb-4">
            <div class="flex items-center justify-between">
              <span>{{ t('KANBAN.FUNNELS.FORM.STAGES.TITLE') }}</span>
              <span
                v-if="editingStage"
                class="text-sm px-2 py-1 rounded"
                :style="{
                  backgroundColor: `${newStage.color}20`,
                  color: newStage.color,
                }"
              >
                {{ t('KANBAN.FUNNELS.FORM.STAGES.EDITING') }}
              </span>
            </div>
          </h4>

          <!-- Nome da Etapa -->
          <div>
            <label class="block text-sm font-medium mb-2">
              {{ t('KANBAN.FUNNELS.FORM.STAGES.NAME') }}
            </label>
            <input
              v-model="newStage.name"
              type="text"
              class="w-full px-3 py-2 border rounded-lg"
              :placeholder="t('KANBAN.FUNNELS.FORM.STAGES.NAME_PLACEHOLDER')"
            />
          </div>

          <!-- Cor e Descrição em flex -->
          <div class="flex items-start gap-2">
            <div class="w-[180px] shrink-0">
              <label class="block text-sm font-medium mb-2">
                {{ t('KANBAN.FUNNELS.FORM.STAGES.COLOR') }}
              </label>
              <StageColorPicker v-model="newStage.color" />
            </div>

            <div class="flex-1 min-w-0">
              <label class="block text-sm font-medium mb-2">
                {{ t('KANBAN.FUNNELS.FORM.STAGES.DESCRIPTION') }}
              </label>
              <input
                v-model="newStage.description"
                type="text"
                class="w-full px-3 py-2 border rounded-lg"
                :placeholder="
                  t('KANBAN.FUNNELS.FORM.STAGES.DESCRIPTION_PLACEHOLDER')
                "
              />
            </div>
          </div>

          <div class="flex justify-end">
            <woot-button
              variant="clear"
              size="small"
              :disabled="!newStage.name"
              @click.stop="handleAddStage"
            >
              <fluent-icon
                :icon="editingStage ? 'checkmark' : 'add'"
                size="16"
                class="mr-2"
              />
              {{ t(editingStage ? 'SAVE' : 'KANBAN.FUNNELS.FORM.STAGES.ADD') }}
            </woot-button>
          </div>
        </div>

        <!-- Botões -->
        <div class="flex justify-end gap-2">
          <woot-button variant="clear" size="small" @click="emit('close')">
            {{ t('CANCEL') }}
          </woot-button>
          <woot-button
            variant="primary"
            size="small"
            type="submit"
            :loading="loading"
            :disabled="!formData.name || !stages.length"
          >
            {{ t(isEditing ? 'SAVE' : 'CREATE') }}
          </woot-button>
        </div>
      </div>

      <!-- Coluna Direita - Lista de Etapas -->
      <div class="border-woot border border-dashed rounded-lg p-6">
        <div class="sticky top-0">
          <h4 class="text-lg font-medium mb-4">
            {{ t('KANBAN.FUNNELS.FORM.STAGES.PREVIEW') }}
          </h4>

          <!-- Lista de Etapas -->
          <div class="space-y-3">
            <draggable
              v-model="stages"
              :options="dragOptions"
              item-key="id"
              class="space-y-3"
              @change="handleOrderChange"
            >
              <template #item="{ element: stage, index }">
                <div
                  class="flex items-center gap-4 p-3 border rounded-lg cursor-move hover:bg-slate-50"
                >
                  <div
                    class="w-4 h-4 rounded-full"
                    :style="{ backgroundColor: stage.color }"
                  />
                  <div class="flex-1">
                    <div class="flex items-center gap-2">
                      <div class="font-medium">
                        {{ stage.name }}
                      </div>
                      <button
                        type="button"
                        class="text-xs hover:underline"
                        :style="{ color: `${stage.color}99` }"
                        @click.stop="startEditing(stage)"
                      >
                        {{ t('KANBAN.FUNNELS.FORM.STAGES.EDIT_STAGE') }}
                      </button>
                    </div>
                    <div class="text-sm text-slate-600">
                      {{ stage.description }}
                      <span class="text-slate-400">({{ stage.id }})</span>
                    </div>
                  </div>
                  <woot-button
                    variant="clear"
                    size="small"
                    @click="removeStage(index)"
                  >
                    <fluent-icon icon="delete" size="16" />
                  </woot-button>
                </div>
              </template>
            </draggable>

            <!-- Empty State -->
            <div
              v-if="!stages.length"
              class="flex flex-col items-center justify-center py-8 text-slate-400"
            >
              <fluent-icon icon="list" size="32" class="mb-2" />
              <p class="text-sm">
                {{ t('KANBAN.FUNNELS.FORM.STAGES.EMPTY') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.border-woot {
  border-color: var(--color-woot);
  border-width: 1px;
}

.ghost-card {
  opacity: 0.5;
  background: #c8ebfb;
}

.cursor-move {
  cursor: move;
}
</style>
