<script setup>
import { ref, markRaw } from 'vue';
import KanbanTab from './components/KanbanTab.vue';
import ListTab from './components/ListTab.vue';
import FunnelsManager from './components/FunnelsManager.vue';
import MessageTemplates from './components/MessageTemplates.vue';

// Usar markRaw para evitar problemas de reatividade com componentes dinâmicos
const components = {
  kanban: markRaw(KanbanTab),
  list: markRaw(ListTab),
  funnels: markRaw(FunnelsManager),
  messageTemplates: markRaw(MessageTemplates),
};

const currentView = ref('kanban');

const switchView = view => {
  if (view in components) {
    currentView.value = view;
  }
};
</script>

<template>
  <div class="flex h-full w-full overflow-hidden">
    <keep-alive>
      <component :is="components[currentView]" @switch-view="switchView" />
    </keep-alive>
  </div>
</template>

<style scoped>
.flex {
  min-height: 0;
  min-width: 0;
  height: 100%;
  width: 100%;
}
</style>
