<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import KanbanAPI from '../../../../api/kanban';
import FunnelAPI from '../../../../api/funnel';
import KanbanColumn from './KanbanColumn.vue';
import KanbanHeader from './KanbanHeader.vue';
import { useStore } from 'vuex';
import KanbanItemForm from './KanbanItemForm.vue';
import Modal from '../../../../components/Modal.vue';
import KanbanItemDetails from './KanbanItemDetails.vue';
import KanbanFilter from './KanbanFilter.vue';

const { t } = useI18n();
const isInitialLoading = ref(false);
const isUpdating = ref(false);
const error = ref(null);
const columns = ref([]);
const store = useStore();
const showAddModal = ref(false);
const selectedColumn = ref(null);
const selectedFunnel = computed(
  () => store.getters['funnel/getSelectedFunnel']
);
const showDeleteModal = ref(false);
const itemToDelete = ref(null);
const showEditModal = ref(false);
const itemToEdit = ref(null);
const showDetailsModal = ref(false);
const itemToShow = ref(null);
const searchQuery = ref('');
const activeFilters = ref(null);
const showFilterModal = ref(false);
const filteredResults = ref({ total: 0, stages: {} });

// Ref para controlar a remontagem
const mountKey = ref(0);

const emit = defineEmits(['switch-view']);

const initializeColumns = async (funnel = null) => {
  try {
    isInitialLoading.value = true;

    if (!funnel) {
      funnel = store.getters['funnel/getSelectedFunnel'];
    }

    console.log('Inicializando colunas com funil:', funnel);

    if (!funnel?.stages) {
      throw new Error('Nenhum funil encontrado');
    }

    columns.value = Object.entries(funnel.stages)
      .map(([id, stage]) => ({
        id,
        title: stage.name,
        color: stage.color,
        description: stage.description,
        position: stage.position,
        items: columns.value.find(col => col.id === id)?.items || [],
      }))
      .sort((a, b) => a.position - b.position);
  } catch (e) {
    error.value = e.message;
    console.error('Erro ao carregar funil:', e);
  } finally {
    isInitialLoading.value = false;
  }
};

const fetchKanbanItems = async (options = { showLoading: true }) => {
  try {
    if (options.showLoading) {
      isUpdating.value = true;
    }

    const currentFunnel = store.getters['funnel/getSelectedFunnel'];
    if (!currentFunnel?.id) return;

    const response = await KanbanAPI.getItems(currentFunnel.id);

    // Atualização mais segura dos dados
    const updatedColumns = columns.value.map(column => {
      const updatedItems = response.data
        .filter(item => item.funnel_stage === column.id)
        .map(item => ({
          id: item.id,
          title: item.item_details?.title || '',
          description: item.item_details?.description || '',
          priority: item.item_details?.priority || 'none',
          assignee: item.item_details?.assignee || null,
          position: item.position || 0,
          funnel_stage: item.funnel_stage,
          item_details: item.item_details || {},
          custom_attributes: item.custom_attributes || {},
          createdAt: item.created_at,
        }));

      return {
        ...column,
        items: updatedItems.sort(
          (a, b) => (a.position || 0) - (b.position || 0)
        ),
      };
    });

    columns.value = updatedColumns;
  } catch (e) {
    console.error('Erro ao carregar itens do Kanban:', e);
  } finally {
    isUpdating.value = false;
  }
};

watch(
  () => store.getters['funnel/getSelectedFunnel'],
  async newFunnel => {
    console.log('Funil selecionado mudou:', newFunnel);
    if (newFunnel) {
      await initializeColumns(newFunnel);
      await fetchKanbanItems();
    }
  }
);

onMounted(async () => {
  const selectedFunnel = store.getters['funnel/getSelectedFunnel'];
  if (selectedFunnel) {
    await initializeColumns(selectedFunnel);
    await fetchKanbanItems();
  }
});

const handleAdd = columnId => {
  const column = columns.value.find(col => col.id === columnId);
  const currentFunnel = store.getters['funnel/getSelectedFunnel'];

  if (column && currentFunnel) {
    // Calculando a próxima posição disponível na coluna
    const position =
      column.items.length > 0
        ? Math.max(...column.items.map(item => item.position || 0)) + 1
        : 0;

    // Abrindo modal com os dados necessários
    showAddModal.value = true;
    selectedColumn.value = {
      id: columnId,
      position: position,
    };

    console.log('Debug - Valores passados ao form:', {
      funnelId: currentFunnel.id,
      stage: columnId,
      position: position,
    });
  }
};

const handleEdit = item => {
  itemToEdit.value = item;
  showEditModal.value = true;
};

const handleDelete = item => {
  itemToDelete.value = item;
  showDeleteModal.value = true;
};

const confirmDelete = async () => {
  try {
    isUpdating.value = true;

    await KanbanAPI.deleteItem(itemToDelete.value.id);
    await fetchKanbanItems({ showLoading: false });

    showDeleteModal.value = false;
    itemToDelete.value = null;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || 'Erro ao excluir o item';
    this.$toast.error(errorMessage);
  } finally {
    isUpdating.value = false;
  }
};

const handleDrop = async ({ itemId, columnId }) => {
  if (!itemId || !columnId) return;

  try {
    isUpdating.value = true;

    // Atualiza localmente primeiro
    const sourceColumn = columns.value.find(col =>
      col.items.some(item => item.id === itemId)
    );
    const targetColumn = columns.value.find(col => col.id === columnId);

    if (sourceColumn && targetColumn) {
      const item = sourceColumn.items.find(item => item.id === itemId);
      if (item) {
        // Remove do source
        sourceColumn.items = sourceColumn.items.filter(i => i.id !== itemId);

        // Adiciona ao target
        targetColumn.items = [
          ...targetColumn.items,
          { ...item, funnel_stage: columnId },
        ].sort((a, b) => (a.position || 0) - (b.position || 0));
      }
    }

    // Chama a API para persistir a mudança
    await KanbanAPI.moveToStage(itemId, columnId);

    // Atualiza do servidor para garantir consistência
    await fetchKanbanItems({ showLoading: false });
  } catch (error) {
    console.error('Erro ao mover item:', error);
    this.$toast?.error(error.response?.data?.message || 'Erro ao mover o item');
  } finally {
    isUpdating.value = false;
  }
};

const calculateFilterResults = (items, filters) => {
  console.log('Calculando resultados para items:', items);
  console.log('Com filtros:', filters);

  const results = {
    total: 0,
    stages: {},
  };

  // Se não houver filtros ativos, retorna zero
  if (
    !filters ||
    (!filters.priority.length &&
      !filters.value.min &&
      !filters.value.max &&
      !filters.agent_id &&
      !filters.date.start &&
      !filters.date.end)
  ) {
    return results;
  }

  items.forEach(item => {
    let matchesFilters = true;

    if (filters.priority.length > 0) {
      matchesFilters =
        matchesFilters && filters.priority.includes(item.item_details.priority);
    }

    if (filters.value.min || filters.value.max) {
      const itemValue = parseFloat(item.item_details.value) || 0;
      if (filters.value.min) {
        matchesFilters =
          matchesFilters && itemValue >= parseFloat(filters.value.min);
      }
      if (filters.value.max) {
        matchesFilters =
          matchesFilters && itemValue <= parseFloat(filters.value.max);
      }
    }

    if (filters.agent_id) {
      matchesFilters =
        matchesFilters && item.item_details.agent_id === filters.agent_id;
    }

    if (filters.date.start || filters.date.end) {
      const itemDate = new Date(item.created_at);
      if (filters.date.start) {
        matchesFilters =
          matchesFilters && itemDate >= new Date(filters.date.start);
      }
      if (filters.date.end) {
        matchesFilters =
          matchesFilters && itemDate <= new Date(filters.date.end);
      }
    }

    if (matchesFilters) {
      const column = columns.value.find(col => col.id === item.funnel_stage);
      const stageName = column?.title || 'Sem etapa';

      if (!results.stages[stageName]) {
        results.stages[stageName] = 0;
      }

      results.stages[stageName]++;
      results.total++;
    }
  });

  console.log('Resultados calculados:', results);
  return results;
};

const handleFilter = filters => {
  console.log('KanbanTab - Filtros recebidos:', filters);

  // Se não houver filtros ativos, resetar para o estado original
  if (
    !filters ||
    (!filters.priority.length &&
      !filters.value.min &&
      !filters.value.max &&
      !filters.agent_id &&
      !filters.date.start &&
      !filters.date.end)
  ) {
    activeFilters.value = null;
    // Recarregar os itens originais
    fetchKanbanItems({ showLoading: false });
    return;
  }

  activeFilters.value = filters;

  // Pega todos os itens de todas as colunas
  const allItems = columns.value.flatMap(column => column.items);
  console.log('Total de itens para filtrar:', allItems.length);

  // Calcula os resultados do filtro
  const results = calculateFilterResults(allItems, filters);
  console.log('KanbanTab - Resultados calculados:', results);

  // Atualiza os resultados filtrados
  filteredResults.value = results;

  // Atualiza as colunas filtradas
  columns.value = columns.value.map(column => ({
    ...column,
    items: column.items.filter(item => {
      let matchesFilters = true;

      if (filters.priority.length > 0) {
        matchesFilters =
          matchesFilters &&
          filters.priority.includes(item.item_details.priority);
      }

      if (filters.value.min || filters.value.max) {
        const itemValue = parseFloat(item.item_details.value) || 0;
        if (filters.value.min) {
          matchesFilters =
            matchesFilters && itemValue >= parseFloat(filters.value.min);
        }
        if (filters.value.max) {
          matchesFilters =
            matchesFilters && itemValue <= parseFloat(filters.value.max);
        }
      }

      if (filters.agent_id) {
        matchesFilters =
          matchesFilters && item.item_details.agent_id === filters.agent_id;
      }

      if (filters.date.start || filters.date.end) {
        const itemDate = new Date(item.created_at);
        if (filters.date.start) {
          matchesFilters =
            matchesFilters && itemDate >= new Date(filters.date.start);
        }
        if (filters.date.end) {
          matchesFilters =
            matchesFilters && itemDate <= new Date(filters.date.end);
        }
      }

      return matchesFilters;
    }),
  }));
};

// Adicionar um novo método para lidar com o fechamento do modal
const handleFilterClose = () => {
  showFilterModal.value = false;

  // Se não houver filtros ativos, recarregar os itens
  if (
    !activeFilters.value ||
    (!activeFilters.value.priority.length &&
      !activeFilters.value.value.min &&
      !activeFilters.value.value.max &&
      !activeFilters.value.agent_id &&
      !activeFilters.value.date.start &&
      !activeFilters.value.date.end)
  ) {
    activeFilters.value = null;
    fetchKanbanItems({ showLoading: false });
  }
};

const handleSettings = () => {
  // Implementar lógica de configurações
  console.log('Settings clicked');
};

// Função para forçar remontagem
const forceRemount = () => {
  mountKey.value += 1;
};

const handleItemCreated = async item => {
  if (!item) return;

  showAddModal.value = false;
  selectedColumn.value = null;

  try {
    // Atualiza localmente primeiro
    const columnIndex = columns.value.findIndex(
      col => col.id === item.funnel_stage
    );
    if (columnIndex !== -1) {
      const updatedColumn = { ...columns.value[columnIndex] };
      updatedColumn.items = [...updatedColumn.items, item].sort(
        (a, b) => (a.position || 0) - (b.position || 0)
      );

      columns.value = [
        ...columns.value.slice(0, columnIndex),
        updatedColumn,
        ...columns.value.slice(columnIndex + 1),
      ];
    }

    // Atualiza do servidor em segundo plano
    await fetchKanbanItems({ showLoading: false });
  } catch (error) {
    console.error('Erro ao criar item:', error);
  }
};

const handleItemEdited = async updatedItem => {
  if (!updatedItem) return;

  showEditModal.value = false;
  itemToEdit.value = null;

  try {
    // Atualiza localmente primeiro
    columns.value = columns.value.map(column => {
      const itemIndex = column.items.findIndex(
        item => item.id === updatedItem.id
      );

      if (itemIndex === -1) return column;

      const updatedItems = [...column.items];
      updatedItems[itemIndex] = {
        ...updatedItems[itemIndex],
        ...updatedItem,
      };

      return {
        ...column,
        items: updatedItems,
      };
    });

    // Atualiza do servidor em segundo plano
    await fetchKanbanItems({ showLoading: false });
  } catch (error) {
    console.error('Erro ao editar item:', error);
  }
};

const handleItemClick = item => {
  itemToShow.value = item;
  showDetailsModal.value = true;
};

const handleEditFromDetails = item => {
  showDetailsModal.value = false;
  handleEdit(item);
};

// Adicionar função para atualizar os itens quando o modal de detalhes for fechado
const handleDetailsUpdate = async () => {
  console.log('Atualizando itens após mudanças nos detalhes');
  await fetchKanbanItems();
};

// Computed para filtrar os itens baseado na busca
const filteredColumns = computed(() => {
  let filtered = columns.value;

  if (searchQuery.value || activeFilters.value) {
    filtered = columns.value.map(column => ({
      ...column,
      items: column.items.filter(item => {
        let matches = true;

        // Filtro por busca
        if (searchQuery.value) {
          const searchTerm = searchQuery.value.toLowerCase();
          const title = (item.title || '').toLowerCase();
          const description = (item.description || '').toLowerCase();
          const assigneeName = (
            item.item_details?.assignee?.name || ''
          ).toLowerCase();

          matches =
            matches &&
            (title.includes(searchTerm) ||
              description.includes(searchTerm) ||
              assigneeName.includes(searchTerm));
        }

        // Filtros avançados
        if (activeFilters.value) {
          const filters = activeFilters.value;

          // Filtro por prioridade
          if (filters.priority.length > 0) {
            matches =
              matches && filters.priority.includes(item.item_details.priority);
          }

          // Filtro por valor
          if (filters.value.min || filters.value.max) {
            const value = item.item_details.value || 0;
            if (filters.value.min) {
              matches = matches && value >= filters.value.min;
            }
            if (filters.value.max) {
              matches = matches && value <= filters.value.max;
            }
          }

          // Filtro por agente
          if (filters.agent_id) {
            matches =
              matches && item.item_details.agent_id === filters.agent_id;
          }

          // Filtro por data
          if (filters.date.start || filters.date.end) {
            const itemDate = new Date(item.created_at);
            if (filters.date.start) {
              matches = matches && itemDate >= new Date(filters.date.start);
            }
            if (filters.date.end) {
              matches = matches && itemDate <= new Date(filters.date.end);
            }
          }
        }

        return matches;
      }),
    }));
  }

  return filtered;
});

// Função para lidar com a busca
const handleSearch = query => {
  searchQuery.value = query;
};

// Computed para calcular os resultados da busca
const searchResults = computed(() => {
  if (!searchQuery.value) return { total: 0, stages: {} };

  const results = {
    total: 0,
    stages: {},
  };

  filteredColumns.value.forEach(column => {
    if (column.items.length > 0) {
      results.stages[column.title] = column.items.length;
      results.total += column.items.length;
    }
  });

  return results;
});

// Atualizar o watch do lastUpdated
watch(
  () => store.state.kanban.lastUpdated,
  async () => {
    if (store.state.kanban.lastUpdated) {
      await fetchKanbanItems();
      forceRemount();
    }
  }
);

defineProps({
  currentView: {
    type: String,
    default: 'kanban',
  },
});
</script>

<template>
  <div :key="mountKey" class="flex flex-col h-full bg-white dark:bg-slate-900">
    <KanbanHeader
      :current-view="currentView || 'kanban'"
      :current-stage="selectedColumn?.id || ''"
      :search-results="filteredResults"
      :columns="columns"
      :active-filters="activeFilters"
      @filter="showFilterModal = true"
      @settings="handleSettings"
      @item-created="handleItemCreated"
      @items-updated="() => fetchKanbanItems({ showLoading: false })"
      @search="handleSearch"
      @switch-view="
        view => {
          console.log('KanbanTab - Emitindo switch-view:', view);
          emit('switch-view', view);
        }
      "
    />

    <!-- Loading State - apenas para carregamento inicial -->
    <div
      v-if="isInitialLoading"
      class="flex justify-center items-center flex-1"
    >
      <span class="loading-spinner" />
    </div>

    <!-- Error State -->
    <div
      v-else-if="error"
      class="flex justify-center items-center flex-1 text-ruby-500"
    >
      {{ error }}
    </div>

    <!-- Columns com indicador sutil de atualização -->
    <div
      v-else
      class="kanban-columns flex flex-1 gap-4 p-4 overflow-x-auto relative"
    >
      <div
        v-if="isUpdating"
        class="absolute top-2 right-2 flex items-center text-sm text-slate-500"
      >
        <span class="loading-spinner-small mr-2" />
        Atualizando...
      </div>

      <KanbanColumn
        v-for="column in filteredColumns"
        :key="column.id"
        :id="column.id"
        :title="column.title"
        :color="column.color"
        :items="column.items"
        :count="column.items.length"
        :description="column.description"
        :total-columns="columns.length"
        @add="() => handleAdd(column.id)"
        @edit="handleEdit"
        @delete="handleDelete"
        @drop="handleDrop"
        @item-click="handleItemClick"
      />
    </div>

    <Modal v-model:show="showAddModal" :on-close="() => (showAddModal = false)">
      <div class="p-6">
        <h3 class="text-lg font-medium">
          {{ t('KANBAN.ADD_ITEM') }}
        </h3>
        <KanbanItemForm
          v-if="selectedColumn && selectedFunnel"
          :funnel-id="selectedFunnel.id"
          :stage="selectedColumn.id"
          :position="selectedColumn.position"
          @saved="handleItemCreated"
          @close="showAddModal = false"
        />
        <div v-else class="text-center text-red-500">
          {{ t('KANBAN.ERRORS.NO_FUNNEL_SELECTED') }}
        </div>
      </div>
    </Modal>

    <Modal
      v-model:show="showDeleteModal"
      :on-close="
        () => {
          showDeleteModal = false;
          itemToDelete = null;
        }
      "
    >
      <div class="p-6">
        <h3 class="text-lg font-medium mb-4">
          {{ t('KANBAN.DELETE_CONFIRMATION.TITLE') }}
        </h3>
        <p class="text-sm text-slate-600 dark:text-slate-400 mb-6">
          {{
            t('KANBAN.DELETE_CONFIRMATION.MESSAGE', {
              item: itemToDelete?.title,
            })
          }}
        </p>
        <div class="flex justify-end space-x-2">
          <woot-button
            variant="clear"
            color-scheme="secondary"
            @click="showDeleteModal = false"
          >
            {{ t('CANCEL') }}
          </woot-button>
          <woot-button
            variant="solid"
            color-scheme="alert"
            @click="confirmDelete"
          >
            {{ t('DELETE') }}
          </woot-button>
        </div>
      </div>
    </Modal>

    <Modal
      v-model:show="showEditModal"
      :on-close="
        () => {
          showEditModal = false;
          itemToEdit = null;
        }
      "
    >
      <div class="p-6">
        <h3 class="text-lg font-medium mb-4">
          {{ t('KANBAN.EDIT_ITEM') }}
        </h3>
        <KanbanItemForm
          v-if="itemToEdit && selectedFunnel"
          :funnel-id="selectedFunnel.id"
          :stage="itemToEdit.funnel_stage"
          :position="itemToEdit.position"
          :initial-data="itemToEdit"
          :is-editing="true"
          @saved="handleItemEdited"
          @close="showEditModal = false"
        />
      </div>
    </Modal>

    <Modal
      v-model:show="showDetailsModal"
      size="medium"
      :on-close="
        () => {
          showDetailsModal = false;
          itemToShow = null;
          handleDetailsUpdate();
        }
      "
    >
      <KanbanItemDetails
        v-if="itemToShow"
        :item="itemToShow"
        @close="
          () => {
            showDetailsModal = false;
            itemToShow = null;
            handleDetailsUpdate();
          }
        "
        @edit="handleEditFromDetails"
        @item-updated="handleDetailsUpdate"
      />
    </Modal>

    <KanbanFilter
      :show="showFilterModal"
      :columns="columns"
      :filtered-results="filteredResults"
      :current-funnel="store.getters['funnel/getSelectedFunnel']"
      @close="handleFilterClose"
      @apply="handleFilter"
    />
  </div>
</template>

<style scoped>
.flex-1 {
  min-height: 0;
}

.kanban-columns {
  /* Estilização da scrollbar para Webkit (Chrome, Safari, etc) */
  &::-webkit-scrollbar {
    height: 8px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-background-light);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-woot);
    border-radius: 4px;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  /* Estilização da scrollbar para Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--color-woot) var(--color-background-light);
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Variáveis CSS para cores consistentes */
:root {
  --color-background-light: #f1f5f9;
}

.dark {
  --color-background-light: #1e293b;
}

.loading-spinner-small {
  width: 16px;
  height: 16px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
</style>
